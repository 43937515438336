import React from 'react';
import Peoplecard from './Peoplecard';
import { Container } from 'react-bootstrap';

const Peoplelist = ({ PeopleArray }) => {
    return (
        <Container fluid>
            <div class="people-list-container">
            {
                PeopleArray.map((user, i) => {
                    const imageUrl = user.profileImage && user.profileImage.url ? user.profileImage.url : ""
                    return (
                        <Peoplecard
                            key={i}
                            id={user.id}
                            name={user.name}
                            role={user.role}
                            company={user.company}
                            website={user.website}
                            imagef={imageUrl}
                        />
                    );
                })
            }
            </div>
        </Container>

    );
}

export default Peoplelist;