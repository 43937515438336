import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import Bankingresearchcardlist from './bankingresearchcardlist';
import Bankingsummarycardlist from './bankingsummarycardlist';
import bank from '../../images/packs-163497.jpg';
import {Button} from 'react-bootstrap';
import './research.css';

class Banking extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div>
                <div >
                <br/>
                <br/>
                    <img class = "image-research"  src={bank} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                    <h1><b>Banking and Financial Intermediation</b></h1>
                    <h6 align = 'justify'>
                        The lab conducts research on the banking and financial intermediation sector including the effect of shocks to the asset side and liability side of the bank balance sheet on corporate borrowers and consumers, bank lending standards, banking crisis and the pricing of bank loans and the covenants in bank loan contracts.
                    </h6>
                    </div>
                </div>

                <Bankingsummarycardlist />
            
                <br/>

                <Bankingresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>
                
            </div>
            );
  }
}

export default Banking;