import React from 'react';
import Researchcard from './researchcard';
import { Container } from 'react-bootstrap';

const ResponsibleFinanceresearchlist = ({ ResearchArray }) => {
    return (
        <Container fluid>
            {
                ResearchArray.map((research, i) => {
                    if ((research.researchField === 'Responsible Finance') && (research.hasResearch === 'Responsible Finance'))
                        return (
                            <div>
                                <Researchcard
                                    key={i}
                                    id={research.id}
                                    elementName={research.elementName}
                                    researchName={research.researchName}
                                    researchWebsite={research.researchWebsite}
                                    researchAuthors={research.researchAuthors}
                                    imageList={research.image}
                                    abstract={research.abstract}
                                    additionalContent={research.additionalContent}
                                />
                                <br />
                            </div>
                        );
                })
            }
        </Container>
    );
}

export default ResponsibleFinanceresearchlist;