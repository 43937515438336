import {gql} from '@apollo/client'

export const getPeopleList = gql`{
    people {
        name
        role
        website
        company
        type
        ranking
        profileImage {
          url
        }
      }
}   
`