import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import {Button} from 'react-bootstrap';
import CorporateFinanceresearchcardlist from './CorporateFinanceresearchcardlist';
import CorporateFinancesummarycardlist from './CorporateFinancesummarycardlist';
import CorporateFinanceimg from '../../images/long-angle-view-of-high-rise-glass-buildings-1055297 (1).jpg';
import './research.css';

class CorporateFinance extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img class = "image-research" src={CorporateFinanceimg} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                        <h1><b>Corporate Finance</b></h1>
                        <h6 align = 'justify'>
                        The lab conducts research on many corporate finance topics such as executive compensation of CEOs and CFOs, the relationship between executive compensation and corporate policies and the three way conflicts between shareholders-bondholders-managers. Another related theme that the lab conducts research is on how firms gain and lose reputation and shareholder class action law suits. 
                        </h6>
                    </div>
                </div>

                <br/>

                <CorporateFinancesummarycardlist />

                <br/>

                <CorporateFinanceresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>
                    
            </div>
            );
  }
}

export default CorporateFinance;