import React, { useState, useEffect, useRef } from 'react';

function ImageCard({ website, imagef, cap1}) {
        return (
            <div>
                <a target="_blank" rel="noopener noreferrer" href={website}>
                    <img style = {{borderRadius: "5px"}} src={imagef} />
                </a>
                <div>
                    <h5 style={myCapStyle}>{cap1}</h5>
                </div>
            </div>
        );
    }


const myCapStyle = {
    paddingTop: "25px",
    fontSize: "25px",
    fontFamily: "'Roboto', sans-serif",
    color: "#54585A",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
}
export default ImageCard;
