import React, {Component} from 'react';
import {Row,Col} from 'react-bootstrap';
import logo2 from '../../images/gt-logo.svg';
import twitter from './footer_img/twitter_logo.png';
import linkedin from './footer_img/linkedin_logo.png';
import github from './footer_img/github_logo.png';
import email from './footer_img/email_logo.png';
import group from './footer_img/group_logo.png';
import map from './footer_img/map_logo.png';
import './footer.css'

class Footer extends Component{
    render(){
        return (
            <footer id="gt-footer">
            <div class="gt-background-gt-footer">
                <div class="gt-background-gt-footer-alt">
                    <div class="logo-container">
                        {/* twitter */}
                        <a href="https://twitter.com/gtfintechlab">
                        <img src = {twitter}/>
                        </a>
                        {/* linkedin */}
                        <a href="https://www.linkedin.com/company/georgia-institute-of-technology-financial-services-innovation-lab/mycompany/">
                        <img src = {linkedin}/>
                        </a>
                        {/* github */}
                        <a href="https://github.com/gtfintechlab">
                        <img src = {github}/>
                        </a>
                        {/* email */}
                        <a href="fintech@gatech.edu">
                        <img src = {email}/>
                        </a>
                        {/* group */}
                        <a href="https://huggingface.co/gtfintechlab">
                        <img src = {group}/>
                        </a>
                        {/* map */}
                        <a href="https://goo.gl/maps/qcFQExMYbsnRfzb78">
                        <img src = {map}/>
                        </a>
                    </div>
                </div>

            <div class="gt-gt-footer">
                <div class="gt-gt-footer-farleftcol">
                    <div class="gt-gt-footer-address">
                    <h2 class="gt-gt-footer-institute">Georgia Institute of Technology</h2>
                    <p style={{fontWeight: "bold", textAlign:"left"}}>North Avenue<br/>Atlanta, GA 30332</p>
                    <p style={{textAlign:"left"}}><i class="svg-phone" aria-hidden="true"></i><a href="tel:+14048942000">+1 404.894.2000</a>
                    <br/><i class="svg-mapmarker" aria-hidden="true"></i><a href="https://map.gatech.edu/">Campus Map</a></p>
                    </div>
                </div>
                <nav class="gt-gt-footer-legalcolumns" role="navigation" aria-label="Legal Links" >
                    <ul class="gt-gt-footer-legallinks">
                    <li><a href="https://directory.gatech.edu/">Directory</a></li>
                    <li><a href="https://careers.gatech.edu/">Employment</a></li>
                    <li><a href="https://www.gatech.edu/emergency/">Emergency Information</a></li>
                    </ul>
                    <ul class="gt-gt-footer-legallinks">
                    <li><a href="https://www.gatech.edu/privacy/">Legal &amp; Privacy Information</a></li>
                    <li><a href="https://gbi.georgia.gov/human-trafficking-notice">Human Trafficking Notice</a></li>
                    <li><a href="https://titleix.gatech.edu/">Title IX/Sexual Misconduct</a></li>
                    <li><a href="https://osi.gatech.edu/hazing-conduct-history">Hazing Public Disclosures</a></li>
                    <li><a href="https://www.gatech.edu/accessibility/">Accessibility</a></li>
                    <li><a href="https://www.gatech.edu/accountability/">Accountability</a></li>
                    <li><a href="https://www.gatech.edu/accreditation/">Accreditation</a></li>
                    </ul>

                </nav>

            <div class="gt-gt-footer-right">
                <a href="https://www.gatech.edu/">
                    <img alt="Georgia Institute of Technology" src="https://dm.lmc.gatech.edu/wp-content/plugins//gt3-branding//images/gt-logo-fullname-wht.svg"></img>
                </a>
                <p class="gt-gt-footer-copyright">©2023 Georgia Institute of Technology</p>
            </div>
            </div>
            </div>
            </footer>
        );
    }
}

export default Footer;  

