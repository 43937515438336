import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import useScreenResize from '../common/useScreenResize';

function JoinInfoCard({ title, content_1, content_2, script, targetRef}) {
    
    const navbarRef = useRef(null);
	const { isMobile, navbarHeight } = useScreenResize(navbarRef);
	const appliedStyles = isMobile ? mobile_styles : styles;

    const scrollToTarget = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    return (
        <div style={appliedStyles.cardStyle}>
            {/* 1. title */}
            <h1 style={appliedStyles.cardTitle}>
                {title}
            </h1>

            {/* 2. content */}
            <div style={appliedStyles.cardBlock}>
            {content_1 ? 
                <>
                    <div style={appliedStyles.cardContent_Container}>
                        <span style={appliedStyles.cardContent_Title}>{content_1.header}</span>
                        <span style={appliedStyles.cardContent_Description}>{content_1.des}</span>
                        <br/>
                        <span style={appliedStyles.cardContent_Title}>{content_2.header}</span>
                        <span style={appliedStyles.cardContent_Description}>{content_2.des}</span>
                    </div> 

                    <div style={appliedStyles.button_Container}>
                        {content_1.button && content_1.id<3 ? <a href={content_1.link}><button style={appliedStyles.cardButton}> {content_1.button} </button> </a>: null}
                        {content_2.button && content_2.id<3 ? <a href={content_2.link}><button style={appliedStyles.cardButton}> {content_2.button} </button> </a>: null}
                        {content_1.button && content_1.id>=3 && content_1.id<=5 ? <button style={appliedStyles.cardButton} onClick={scrollToTarget}> {content_1.button} </button> : null}
                        {content_2.button && content_2.id>=3 && content_1.id<=5 ? <button style={appliedStyles.cardButton} onClick={scrollToTarget}> {content_2.button} </button> : null}
                        {content_1.button && content_1.id>=6 ? <a href={content_1.link}><button style={appliedStyles.cardButton}> {content_1.button} </button> </a>: null}
                        {content_2.button && content_2.id>=6 ? <a href={content_2.link}><button style={appliedStyles.cardButton}> {content_2.button} </button> </a>: null}
                    </div>
                </> 
                : null } 
        </div>
        
        {/* 3. script */}
        <p style={{...appliedStyles.cardContent_Title, padding: "0 30px 0"}}>{script}</p>
    </div>
    );
}

const styles = {
    cardStyle: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: 'rgba(217, 217, 217, 0.35)',
    },

    cardTitle: {
        color: "#54585A",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "35px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "39.5px",
        padding: "25px 30px 10px",
        flexShrink: 0,
        textAlign: "left",
    },

    cardBlock: {
        display: "flex",
        flexDirection: "row",
    },

    cardContent_Container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "0px 41px 0px 30px",
    },

    cardContent_Title: {
        color: "#003057",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "25px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "30px",
        textAlign: "left",
    },

    cardContent_Description: {
        color: "#54585A",
        fontFamily: "Roboto",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "30px",
        textAlign: "left",
    },

    button_Container: {
        display: "flex",
        flexDirection: "column",
        padding: "30px 41px 40px 0 ",
        gap: "80px",
    },

    cardButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "30px",
        width: "150px",
        background: "#003057",
    },
    
}

const mobile_styles = {
    cardStyle: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: 'rgba(217, 217, 217, 0.35)',
    },

    cardTitle: {
        color: "#54585A",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "25px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "39.5px",
        padding: "25px 20px 10px",
        flexShrink: 0,
        textAlign: "left",
    },

    cardBlock: {
        display: "flex",
        flexDirection: "row",
    },
    
    cardContent_Container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "0px 20px 0px 30px",
    },

    cardContent_Title: {
        color: "#003057",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "30px",
        textAlign: "left",
    },

    cardContent_Description: {
        color: "#54585A",
        fontFamily: "Roboto",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        textAlign: "left",
        paddingBottom: "10px",
    },

    button_Container: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 10px 40px 0 ",
        gap: "150px",
    },

    cardButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "30px",
        width: "78px", 
        background: "#003057",
    },
}
    

export default JoinInfoCard;
