import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import {Button} from 'react-bootstrap';
import ResponsibleFinanceresearchcardlist from './responsiblefinanceresearchcardlist';
import ResponsibleFinancesummarycardlist from './responsiblefinancesummarycardlist';
import responsible_finance from '../../images/money-2724235.jpg';
import './research.css';

class ResponsibleFinance extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img class = "image-research" src={responsible_finance} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">
                        <h1><b>Responsible and Sustainable Finance</b></h1>
                        <h6 align = "justify">
                            The lab conducts research on topics related to Responsible and Sustainable Finance. Businesses have multiple stakeholders including stock holders, employees and the communities in which they operate. The lab aims to shed some light on the impact of technological innovation and various corporate policies on firms’ stakeholders. Some examples of the research the lab conducts under this theme are ESG Investing and Labor and Finance.
                        </h6>
                    </div>
                </div>

                <ResponsibleFinancesummarycardlist />

                <br/>

                <ResponsibleFinanceresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>

            </div>
            );
  }
}

export default ResponsibleFinance;