import { when } from 'jquery';
import React, { Component } from 'react';

class ProjectCard extends Component {
    render() {
        const { projectImage, projectDescription, projectLink, projectTitle } = this.props.project;
        let isMobile = false;
        if (this.props.isMobile) {
            isMobile = this.props.isMobile;
        }
        const appliedStyle = this.props.isMobile ? styles.mobile : styles.desktop;
        return (
            <div style={styles.projectCardStyle}>
                <h2><strong>{projectTitle}</strong></h2>
                <div>
                </div>
                <div style={appliedStyle.contentStyle}>
                    <img src={projectImage} alt={projectImage.alt} style={appliedStyle.imageStyle} />
                    <div>
                        <p style={styles.projectDescriptionStyle}>{projectDescription}</p>
                        <div style={{ padding: "20px" }}>
                            <a href={projectLink}>
                                <button style={styles.learnMoreButton}> Learn More </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const styles = {
    desktop: {
        contentStyle: {
            display: "flex",
        },
        imageStyle: {
            width: "40%",
            maxWidth: "700px",
            minWidth: "400px",
            margin: "1rem",
            objectFit: "cover"
        },
    },
    mobile: {
        contentStyle: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
        },
        imageStyle: {
            width: "80%",
            maxWidth: "700px",
            margin: "1rem",
            objectFit: "contain"
        },
    },
    projectCardStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "rgba(214, 219, 212, 0.35)",
        borderRadius: "5px",
        margin: "10px",
        padding: "20px",
        width: "90%",
        minWidth: "700px"
    },
    projectImageStyle: {
        width: "200px",
        height: "200px",
        borderRadius: "5px",
        marginBottom: "20px"
    },

    projectDescriptionStyle: {
        paddingLeft: "20px",
        paddingTop: "10px",
        fontFamily: "'Roboto', sans-serif",
        fontSize: "16px",
        fontWeight: "400",
        textAlign: "left",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: "30",
        WebkitBoxOrient: "vertical",
    },
    learnMoreButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "30px",
        width: "150px",
        background: "#003057",
    },
}

export default ProjectCard;
