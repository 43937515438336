import React, { Component } from 'react';
import Fintechresearchcardlist from './fintechresearchcardlist';
import Fintechsummarycardlist from './fintechsummarycardlist';
import fintech from '../../images/blue-and-yellow-graph-on-stock-market-monitor-159888.jpg';
import {Card, Container,Row,CardDeck,Button} from 'react-bootstrap';
import {Element, animateScroll as scroll} from 'react-scroll';
import tag20181 from '../../images/tag_fintech_2018_1.png';
import tag20182 from '../../images/tag_fintech_2018_2.PNG';
import tag20183 from '../../images/tag_fintech_2018_3.PNG';
import tag20161 from '../../images/tag_fintech_2016_1.PNG';
import tag20162 from '../../images/tag_fintech_2016_2.PNG';
import tag20163 from '../../images/tag_fintech_2016_3.PNG';
import './research.css';

class FinTech extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img class = "image-research" src={fintech} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                        <h1><b>FinTech and Innovation</b></h1>
                        <h6 align = "justify">
                            FinTech is disrupting traditional financial intermediation and is changing every function and service that financial intermediaries provide - right from lending to payment services, from currency transactions to trading and wealth management to real estate, capital markets and insurance. The lab conducts research on various aspects of FinTech and their impact on traditional banks and consumers. The lab has collaborated with TAG-FinTech society to prepare FinTech ecosystem reports that highlight the contribution of the FinTech industry to Georgia’s economy. The lab also provides support to a first of its kind class in Georgia on “FinTech Ventures” that was taught for banking executives since 2013 and for MBA students since 2015.
                        </h6>
                    </div>
                </div>

                <br/>

                <Fintechsummarycardlist />

                <br/>

                <Fintechresearchcardlist />

                <Container fluid>
                    <Card>
                        <Element name="2018SGFE" className="element" >
                        <Card.Header>
                            <h4><b>2018 State of Georgia's FinTech Ecosystem</b></h4>
                        </Card.Header>
                        </Element>
                        <Card.Body>
                            <Row>
                                <Container fluid>
                                    <p align = 'justify'>
                                    Georgia’s FinTech industry is inevitably associated
                                    with the payments sector, given the state’s remarkable
                                    strength in this area- hence the nickname Transaction
                                    Alley. Georgia continues to boast seven of the
                                    nine largest U.S. card acquirers, even after some
                                    shakeup at the top of the leaderboard. Since
                                    our last report, Atlanta-based Global Payments
                                    completed its acquisition of Heartland Payment
                                    Systems. More recently, Vantiv acquired Worldpay
                                    (whose US headquarters are based in Atlanta), with
                                    the combined entity continuing under the Worldpay
                                    name and maintaining a local management structure.
                                    Earlier in 2017 Vantiv also built upon its Atlanta-based
                                    portfolio with the acquisition of Paymetric.
                                    </p>
                                </Container>
                            </Row>
                            <Row>
                                <CardDeck>
                                    <Card>
                                        <Card.Header>
                                            <h5>Georgia FinTech Ecosystem</h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '300px'}} variant="top" src={tag20181} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Most critical FinTech initiatives</h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '300px'}} variant="top" src= {tag20182} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Global financing volume into technology</h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '300px'}} variant="top" src={tag20183} />
                                    </Card>
                                </CardDeck>
                            </Row>
                            <br/>
                            <p align = 'left'>
                                <Button size = 'sm' target="_blank" rel="noopener noreferrer" href = "https://www.fintechatlanta.org/assets/pdf/2017-tag-fintech-ecosystem-report.PDF" variant="warning">Read more</Button>{' '}
                            </p>
                        </Card.Body>
                    </Card>

                    <br/>

                    <Card>
                        <Element name="2016SGFE" className="element" >
                        <Card.Header>
                            <h4><b>2016 State of Georgia's FinTech Ecosystem</b></h4>
                        </Card.Header>
                        </Element>
                        <Card.Body>
                            <Row>
                                <Container fluid>
                                    <p align = 'justify'>
                                    Georgia’s financial technology industry is ready for its close-up. For years, the sector’s leading companies were content to play a behind the scenes role creating and running the critical infrastructure that
                                    enables much of the world’s financial services, particularly payments. Since the financial crisis, however, several of these companies have recognized the value of taking more visible positions in advocacy on topics such as legislation and data security, engaging in public/private partnerships, etc.
                                    The Georgia FinTech sector encompasses about 100 companies ranging from Fortune 500 bellwethers
                                    to early stage start ups. There are firms whose time in the state dates to the 1800s (Equifax), several
                                    that moved to Georgia at varying stages of their evolution (NCR, CheckFree, Groundfloor), one that
                                    left Georgia only to return (First Data), and one that recently undertook a national search for a headquarters
                                    location before determining it already had the ideal locale (Worldpay).  
                                    </p>
                                </Container>
                            </Row>
                            <Row>
                                <CardDeck>
                                    <Card>
                                        <Card.Header>
                                            <h5>Georgia-based FinTech Statistics</h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '300px'}} variant="top" src={tag20161} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Key Drivers of Tehnology Investment</h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '300px'}} variant="top" src= {tag20162} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>FinTech job functions to be emphasized</h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '300px'}} variant="top" src={tag20163} />
                                    </Card>
                                </CardDeck>
                            </Row>
                            <br/>
                            <p align = 'left'>
                                <Button size = 'sm' target="_blank" rel="noopener noreferrer" href = "http://www.prism.gatech.edu/~schava6/TAGFinTechReport2016.pdf" variant="warning">Read more</Button>{' '}
                            </p>
                        </Card.Body>
                    </Card>
                </Container>

                <br/>

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>

            </div>
            );
  }
}

export default FinTech;