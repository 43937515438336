import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import {Button} from 'react-bootstrap';
import household_finance from '../../images/apple-devices-books-business-coffee-572056.jpg';
import Householdfinanceresearchcardlist from './householdfinanceresearchcardlist';
import Householdfinancesummarycardlist from './householdfinancesummarycardlist';
import './research.css';

class HouseholdFinance extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                
                <div >
                    <img class = "image-research" src={household_finance} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">
                        <h1><b>Household Finance</b></h1>
                        <h6 align = 'justify'>
                            The lab conducts research on economic choices and decisions that households make and the constraints 
                            they face in their decision making. Some of the topics of interest include: household borrowing 
                            consumption using credit cards and other unsecured borrowing, housing and mortgage decisions, 
                            investment choices, wages and labor income and retirement choices of individuals.  
                        </h6>
                    </div>
                </div>

                <Householdfinancesummarycardlist />

                <br/>

                <Householdfinanceresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>

            </div>
            );
  }
}

export default HouseholdFinance;