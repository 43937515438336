import React from 'react';
import Summarycard from './summarycard';
import { Container } from 'react-bootstrap';

const RealEstatesummarylist = ({ ResearchArray }) => {
    return (
        <Container fluid>
            <div class="research-summary-container">
            {
                ResearchArray.map((research, i) => {
                    if (research.researchField === 'Real Estate')
                        return (
                            <Summarycard
                                key={i}
                                id={research.id}
                                elementName={research.elementName}
                                researchName={research.researchName}
                                image1={research.image[0]}
                            />
                        );
                })
            }
            </div>
        </Container>
    );
}

export default RealEstatesummarylist;