import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import {Button} from 'react-bootstrap';
import Cryptoresearchcardlist from './cryptoresearchcardlist';
import Cryptosummarycardlist from './cryptosummarycardlist';
import crypto from '../../images/blockchain-3206918_1920.png';/* https://pixabay.com/illustrations/blockchain-cryptocurrency-finance-3206918/ */
import './research.css';

class Crypto extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div>
                    <img class = "image-research" src={crypto} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                        <h1><b>Blockchain, Crypto Currencies and DeFi</b></h1>
                        <h6 align = "justify">
                        Blockchain and Distributed Ledger Technology (DLT) have a tremendous potential to fundamentally restructure the financial services industry. Georgia Tech’s FinTech Lab conducts research on applications of Block chain in the Financial Services industry, The FinTech lab also conducts research on Crypto Currencies and Decentralized Finance (DeFi).  Some of the recent projects include initial coin offerings (ICOs), analyzing data from various block chains to understand the network structure etc., The FinTech Lab sponsors Blockchain club at Georgia Tech and offers support to the FinTech and Crypto Currencies class. 
                        </h6>
                    </div>
                </div>

                <Cryptosummarycardlist />
                
                <br/>

                <Cryptoresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>
                    
            </div>
            );
  }
}

export default Crypto;