import React, { useState, useEffect, useRef } from 'react';
import useScreenResize from '../common/useScreenResize';
//import vip_image from '../../images/vip_image.png'
import vip_image from '../../images/vip_image_website.png'
import ProjectCard from './project_Card';

const projectList = [
    {
        projectTitle: "Stock Valuation Research",
        projectDescription: `
Welcome to the Stock Valuation Research, a Georgia Tech Vertically Integrated Projects (VIP) initiative. We're a student-run equity research team focused on the transportation sector. Our in-depth analysis, grounded in fundamental methods, offers valuable insights into this dynamic industry, fostering collaborations with local businesses and investors. This class is taught by Michael Messner. 

Discounted Cash Flow (DCF) valuation is a method used to estimate the value of a company by forecasting its future cash flows and discounting them back to their present value. The students of the Stock Valuation Research VIP Program have prepared detailed models of the historical and future financial performance ofthe companies in order to value each company using discounted cash flow. Click below for a detailed breakdown of the website.

`,
        projectImage: vip_image,
        projectLink: "https://gtequityresearch.up.railway.app"
    },
]


function Projects() {


    const navbarRef = useRef(null);
    const { isMobile, navbarHeight } = useScreenResize(navbarRef);
    const appliedStyles = isMobile ? mobile_styles : styles;
    return (
        <div style={appliedStyles.vipContainer}>
            <h1 id="vip" style={appliedStyles.vipTitle}> VIP Projects </h1>
            {projectList.map(project => (
                <ProjectCard isMobile={isMobile} project={project} />
            ))}
        </div>
    );
}

const styles = {
    mainDescription: {
        color: "#54585A",
        textAlign: "left",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        padding: "55px 211px 31px",
    },

    cardsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 240px 50px",
        gap: "45px",
    },

    vipTitle: {
        color: "#54585A",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "35px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal"
    },

    vipContainer: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        padding: "30px 240px 130px",
        gap: "30px",
    },

    vipBlock: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },

    vipTopText: {
        display: "flex",
        flexDirection: "column",
        color: "#003057",
        textAlign: "left",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal"
    },

    vipBlockDown: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },

    vipButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "30px",
        width: "150px",
        background: "#003057",
    },

};

const mobile_styles = {
    mainDescription: {
        color: "#54585A",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        padding: '0px 20px 30px',
    },

    cardsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 20px 50px",
        gap: "45px",
    },

    vipTitle: {
        color: "#54585A",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal"
    },

    vipContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "30px 10px 130px",
        gap: "30px",
        justifyContent: "center",
    },

    vipBlock: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 15px",
        gap: "20px",
    },

    vipTopText: {
        display: "flex",
        flexDirection: "column",
        color: "#54585A",
        textAlign: "left",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal"
    },

    vipButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "30px",
        width: "150px",
        background: "#003057",
    },

};

export default Projects;
