import React, { useRef } from 'react';
import useScreenResize from '../common/useScreenResize';

function StudentPartners({imgf, text, link}){

  const navbarRef = useRef(null);
  const { isMobile, navbarHeight } = useScreenResize(navbarRef);
  const appliedStyles = isMobile ? mobile_styles : styles;
  
  return (
    <div style={appliedStyles.container}>
        <img src={imgf} style={appliedStyles.logoImage}/>
        <p style={appliedStyles.myDescription}>{text}</p>
        <a href={link}><button style={appliedStyles.myButton}> Learn More </button> </a>
    </div>
  );
}

/* styles */
const styles = {
    container:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
    },

    logoImage: {
        maxWidth: "200px", 
    },

    myDescription: {
        color: "#000",
        textAlign: "center",  
        fontFamily: "'Roboto', sans-serif",
        fontSize: "12px",  
        fontStyle: "normal", 
        fontWeight: 400, 
        lineHeight: "normal", 
    },

    myButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: "'Roboto', sans-serif",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "35px",
        width: "95px",
        background: "#003057",
    }

}

const mobile_styles = {
    container:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
        marginBottom: "20px",
    },

    logoImage: {
        maxWidth: "200px", 
    },
    
    myDescription: {
        color: "#000",
        textAlign: "center",  
        fontFamily: "'Roboto', sans-serif",
        fontSize: "12px",  
        fontStyle: "normal", 
        fontWeight: 400, 
        lineHeight: "25px",        
        height:"14px",
    },

    myButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: "'Roboto', sans-serif",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "20px",
        width: "70px",
        background: "#003057",
    }
}

export default StudentPartners;
