import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import {Button} from 'react-bootstrap';
import Smallbusinessresearchcardlist from './smallbusinessresearchcardlist';
import Smallbusinesssummarycardlist from './smallbusinesssummarycardlist';
import smallbusinessimg from '../../images/top-view-photo-of-people-near-wooden-table-3183150 (1).jpg';
import './research.css';

class Smallbusiness extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img class = "image-research" src={smallbusinessimg} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                        <h1><b>Small Business</b></h1>
                        <h6 align = "justify">
                            Small businesses are a vital component of the U.S. economy accounting for almost 50% of the non-farm GDP. Opening and closing of small businesses, with less than ten employees, accounted for more than 70% of job gains and losses in 2018 (Bureau of Labor Statistics (BLS)). The lab conducts research on issues relevant to the entry and exit of small businesses and various factors that determine the survival of the small businesses. 
                        </h6>
                    </div>
                   
                </div>

                <Smallbusinesssummarycardlist />

                <br/>

                <Smallbusinessresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>
                    
            </div>
            );
  }
}

export default Smallbusiness;