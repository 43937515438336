import React from 'react';
import { Container } from 'react-bootstrap';
import Peoplelist from './Peoplelist';
import { useQuery } from "@apollo/client";
import { getPeopleList } from './PeopleService';
import { getHeadingFromType, getPriorityFromType, getSortMethodByType  } from './utils';

const PeopleCardList = () => {
    const { loading, error, data } = useQuery(getPeopleList)
    if (loading || error) return ""
    let people_list = data.people.reduce((people_list, item) => {
        const type_list = (people_list[item.type] || [])
        type_list.push(item)
        people_list[item.type] = type_list
        return people_list;
    }, {});

    Object.keys(people_list).forEach(key => {
        const sorted_list = people_list[key].sort((item1, item2) => {
            return compareBySortMethods(key, item1, item2)
        })
        people_list[key] = sorted_list
    })

    people_list = Object.keys(people_list).sort((item1, item2) => getPriorityFromType(item1) - getPriorityFromType(item2))
        .reduce((res, key) => (res[key] = people_list[key], res), {})
    return (
        <div>
            {Object.keys(people_list).map(key => {
                return (<Container fluid>
                    <h2><b>{getHeadingFromType(key)}</b></h2>
                    <Peoplelist PeopleArray={people_list[key]} />
                </Container>)
            })}
        </div>
    )
}

const compareBySortMethods = (type, item1, item2) => {
    if(getSortMethodByType(type) == "Rank-Based-Sorting") {
        if (item1.ranking === item2.ranking) return 0;
        else return item1.ranking < item2.ranking ? -1 : 1
    } else {
        if (item1.name === item2.name) return 0;
        else return item1.name < item2.name ? -1 : 1        
    }
}

export default PeopleCardList;