import React from 'react';
import Newslist from './newslist';
import { useQuery } from '@apollo/client';
import { getNews } from './newsService';


const Newscardlist = () => {
    const { loading, error, data } = useQuery(getNews)
    if (loading || error) return ""
    return (
        <Newslist ResearchArray={data.allNews} />
    )
}

export default Newscardlist;