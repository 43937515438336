import React, { Component } from 'react';
import {Element, animateScroll as scroll} from 'react-scroll';
import {Card, Container,Row,CardDeck,Button} from 'react-bootstrap';
import Creditriskresearchcardlist from './creditriskresearchcardlist';
import {Link} from 'react-router-dom';
import Creditrisksummarycardlist from './creditrisksummarycardlist';
import credit_risk from '../../images/dices-over-newspaper-2656028.jpg';
import Bankruptcy1 from '../../images/Bankruptcy_1.PNG';
import Bankruptcy2 from '../../images/Bankruptcy_2.PNG';
import Bankruptcy3 from '../../images/Bankruptcy_3.PNG';
import './research.css';

class CreditRisk extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img class = "image-research" src={credit_risk} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research"> 	
                        <h1><b>Credit Risk</b></h1>
                        <h6 align = 'justify'>
                            The lab conducts research on developing prediction models for default and expected loss both for firms (small businesses, private firms and public firms) and for consumers and households. The lab sheds light on the bankruptcy process, credit default swaps and other credit derivatives through its research. In addition, the lab also provides free of charge (for academic research purposes), a comprehensive bankruptcy database of U.S. public firms from 1963 to 2019. 
                        </h6>
                    </div>
                </div>

                <br/>

                <Creditrisksummarycardlist />

                <br/>


                <Container fluid>
                    <Card>
                        <Element name="USBA" className="element" >
                            <Card.Header>
                                <h4><b>United States Bankruptcy Analysis</b></h4>
                            </Card.Header>
                        </Element>
                        <Card.Body>
                            <Row>
                                <Container fluid>
                                <p align = 'justify'>
                                    Companies file bankruptcy when they are unable to pay their debt. Between 1964 and 2019 more than 3900 public companies filed bankruptcy. We have analysed the bankruptcy data by sector, state, age and market capitalization over time. 
                                </p>
                                </Container>
                            </Row>
                            <Row>
                                <CardDeck>
                                    <Card>
                                        <Card.Header>
                                            <h5>Bankruptcy over time</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src={Bankruptcy1} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Statewise Bankruptcy</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src= {Bankruptcy2} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Sectorwise Bankruptcy</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src={Bankruptcy3} />
                                    </Card>
                                </CardDeck>
                            </Row>
                            <br/>
                            <p align = 'left'>
                                <Button size = 'sm' variant="warning"><Link to='/research/creditrisk/bankruptcy'>Read more</Link></Button>{' '}
                            </p>
                        </Card.Body>
                    </Card>
                </Container>

                <br/>

                <Creditriskresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>

            </div>
            );
  }
}

export default CreditRisk;