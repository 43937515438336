import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll'
import cyber from '../../images/cyber-security.jpg';
import CyberSecurityresearchcardlist from './cybersecurityresearchcardlist';
import CyberSecuritysummarycardlist from './cybersecuritysummarycardlist';
import {Card, Container,Row,CardDeck,Button} from 'react-bootstrap';
import cyber1 from '../../images/comprehensive-research.jpg';
import cyber2 from '../../images/state-of-the-art-facilities.jpg';
import cyber3 from '../../images/Licensable.jpg';
import './research.css';

class CyberSecurity extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img  class = "image-research" src={cyber} alt="" style={{width:'100%'}}/>
                        <div class="text-block-research">
                            <h1><b>Cyber Security</b></h1>
                            <h6 align = "justify">Sudheer Chava, Ph.D, is an Associate Director of the Institute for Information Security & Privacy (IISP) for the area of Risk Management. The IISP at Georgia Tech connects government, industry and academia to solve the grand challenges of cybersecurity. </h6>
                        </div>
                </div>

                <br/>

                <CyberSecuritysummarycardlist />

                <Container fluid>
                    <Card>
                        <Card.Header>
                            <h4><b>Georgia Tech Cyber Security</b></h4>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Container fluid>
                                    <p align = 'justify'>
                                        The Institute for Information Security & Privacy (IISP) at Georgia Tech connects government, industry and academia to solve the grand challenges of cybersecurity. As a coordinating body for 12 labs and centers dedicated to academic and solution-oriented applied research, the IISP leverages intellectual capital from across Georgia Tech and external partners to address vital solutions for national defense, economic continuity, and individual freedom. <br/>
                                    </p>
                                </Container>
                            </Row>
                            <Row>
                                <CardDeck>
                                    <Card>
                                        <Card.Header>
                                            <h5>Comprehensive Research</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src={cyber1} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>State-of-the-art lab facilities</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src= {cyber2} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Licensable I.P.</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src={cyber3} />
                                    </Card>
                                </CardDeck>
                            </Row>
                            <br/>
                            <p align = 'left'>
                                <Button size = 'sm' target="_blank" rel="noopener noreferrer" href = "https://cyber.gatech.edu/" variant="warning">Read more</Button>{' '}
                            </p>
                        </Card.Body>
                    </Card>

                </Container>

                <br/>

                <CyberSecurityresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>
            
            </div>
            );
  }
}

export default CyberSecurity;
