import React from 'react';
import Eventscard from './eventscard';
import { Container } from 'react-bootstrap';

const Eventlist = ({ EventArray }) => {
    return (
        <Container fluid>
            {
                EventArray.map((event, i) => {
                    return (
                        <Eventscard
                            key={i}
                            id={i}
                            eventimage={event.image}
                        />
                    );
                })
            }
        </Container>
    );
}

export default Eventlist;