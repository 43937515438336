import React, { useState, useEffect, useRef } from 'react';
import useScreenResize from '../common/useScreenResize';
import JoinInfoCard from './join_info_card';
import { Link } from 'react-router-dom';
import vip_image from '../../images/vip_image.png'

function JoinUs() {
	const navbarRef = useRef(null);
	const vipRef = useRef(null);
	const { isMobile, navbarHeight } = useScreenResize(navbarRef);
	const appliedStyles = isMobile ? mobile_styles : styles;

	const joinCardContents = [
        { id: 0, button: "APPLY", header: 'Machine Learning', link: 'https://www.ml.gatech.edu/phd/admissions', des: 'To apply for the program, use the given link and select CSE as the home school.' },
        { id: 1, button: "APPLY", header: 'Finance', link: 'https://www.scheller.gatech.edu/explore-programs/phd/academics/concentrations/finance.html', des: 'To apply for the program, use the given link.' },
        { id: 2, button: "APPLY", header: 'Prospective Students', link: 'https://gradapp.gatech.edu/apply/', des: 'Interested students can apply for the MS QCF program using the given link' },
		{ id: 3, button: "LEARN MORE", header: 'Current Students', link: '#vip', des: 'Already enrolled masters students can get involved through dual enrolment in the MS QCF program. Students can also apply for one of the three VIP projects' },
		{ id: 4, button: "LEARN MORE", header: 'Current Students', link: '#vip', des: 'Interested students can get involved by applying for one of the three VIP projects' },
		{ id: 5, button: null, header: 'FinTech Fellow', link: null, des: 'Exceptional current students can be considered for the FinTech Fellowship program. Applications for the program will open up at the beginning of every semester' },
		{ id: 6, button: "LINK", header: 'Business/Finance Major', link: 'https://gatech.co1.qualtrics.com/jfe/form/SV_3xUYKWr7ltcOVdc', des: 'For more details and application, use the given link.' },
		{ id: 7, button: "LINK", header: 'Computing and Engineering Major', link: 'https://gatech.co1.qualtrics.com/jfe/form/SV_4GgDKT7AC0qSHCm', des: 'For more details and application, use the given link.' },
	];

    return(
		<div className = "joinus">

			{/* 1. description */}
			<h1 style={appliedStyles.mainDescription}>
				We have multiple positions for students. <br />Please fill the relevant application form for the position that interests you.
			</h1>

			{/* 2. cards */}
			<div style={appliedStyles.cardsContainer}>
				<JoinInfoCard 
					title = "Summer 2024 (Closed)"
					content_1 = { joinCardContents[6] } 
					content_2 = { joinCardContents[7] } 
					script = ""
				/>
				<JoinInfoCard 
					title = "Post Doc/ Research Scientist"
					script = "We are seeking highly motivated individuals to contribute to our Lab’s vision. If interested in the opportunity, please email Dr. Sudheer Chava (Director): chava@gatech.edu"
				/>
				<JoinInfoCard 
					title = "Phd Students"
					content_1 = { joinCardContents[0] } 
					content_2 = { joinCardContents[1] } 
					script = "We recruit 1-2 new PhD students every year. If you are interested, please apply to the PhD program and list Dr. Sudheer Chava as a potential advisor."
				/>
				<JoinInfoCard 
					title = "Masters Students"
					content_1 = { joinCardContents[2] } 
					content_2 = { joinCardContents[3] } 
					targetRef={vipRef}
				/>
				<JoinInfoCard 
					title = "Undergrad Students"
					content_1 = { joinCardContents[4] } 
					content_2 = { joinCardContents[5] } 
					targetRef={vipRef}
				/>
			</div>

			{/* 3. VIP */}
			<h1 ref={vipRef} id = "vip" style={appliedStyles.vipTitle}> VIP Program </h1>
			<div style={appliedStyles.vipContainer}>
				<img src={vip_image}/>
				<div style={appliedStyles.vipBlock}>
					<p style={appliedStyles.vipTopText}>
						The Vertically Integrated Projects (VIP) Program is a transformative approach to enhancing higher education by engaging undergraduate and graduate students in ambitious, large-scale and multidisciplinary project teams that are led by faculty. Under the VIP programs, students are equipped with modern techniques and tools to tackle financial project statements in long term projects.
					</p>
					<div style={appliedStyles.vipBlockDown}>
						<div style={appliedStyles.vipTopText}>
							<p>VIP courses offered through our lab:</p>
							<ul>
								<li> NLP for Financial Markets</li>
								<li> ML for Financial Markets</li>
								<li> Equity Research Boutique</li>
							</ul>
						</div>
						
						<div style={{ padding:"60px 20px 0px 50px" }}>
							<a href="https://www.vip.gatech.edu/graduate-students">
								<button style={appliedStyles.vipButton}> APPLY </button>
							</a>
						</div>
					</div>

				</div>
			</div>
					
		</div>
    );

}

const styles = {
    mainDescription: {
        color: "#54585A",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
		padding: "55px 211px 31px",
    },

	cardsContainer: {
		display: "flex",
        flexDirection: "column",
		alignItems: "center",
		padding: "0px 240px 50px",
		gap: "45px",
	},

	vipTitle: {
		color: "#54585A",
		textAlign: "center",
		fontFamily: 'Roboto, sans-serif',
		fontSize: "35px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal"	
	},

	vipContainer: {
		display: "flex",
        flexDirection: "row",
		alignItems: "center",
		padding: "30px 240px 130px",
		gap: "30px",
	},

	vipBlock: {
		display: "flex",
        flexDirection: "column",
		gap: "20px",
	},

	vipTopText: {
		display: "flex",
        flexDirection: "column",
		color: "#003057",
		textAlign: "left",
		fontFamily: 'Roboto, sans-serif',
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "normal"
	},

	vipBlockDown: {
		display: "flex",
        flexDirection: "row",
	},

	vipButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "30px",
        width: "150px",
        background: "#003057",
    },

};

const mobile_styles = {
	mainDescription: {
        color: "#54585A",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
		padding:'0px 20px 30px',
    },

	cardsContainer: {
		display: "flex",
        flexDirection: "column",
		alignItems: "center",
		padding: "0px 20px 50px",
		gap: "45px",
	},

	vipTitle: {
		color: "#54585A",
		textAlign: "center",
		fontFamily: 'Roboto, sans-serif',
		fontSize: "20px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal"	
	},

	vipContainer: {
		display: "flex",
        flexDirection: "column",
		alignItems: "center",
		padding: "30px 10px 130px",
		gap: "30px",
	},

	vipBlock: {
		display: "flex",
        flexDirection: "column",
		padding: "0px 15px",
		gap: "20px",
	},

	vipTopText: {
		display: "flex",
        flexDirection: "column",
		color: "#54585A",
		textAlign: "left",
		fontFamily: 'Roboto, sans-serif',
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "normal"
	},

	vipButton: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: 'Roboto, sans-serif',
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        borderRadius: "5px",
        height: "30px",
        width: "150px",
        background: "#003057",
    },

};

export default JoinUs;
