import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import Header from './components/header/header';
import About from './components/about/about';
import Research from './components/miscellaneous/research';
import Publications from './components/miscellaneous/publications';
import COVID from './components/research/covid';
import HouseholdFinance from './components/research/householdfinance';
import FinTech from './components/research/fintech';
import ResponsibleFinance from './components/research/responsiblefinance';
import Trading from './components/research/trading';
import CreditRisk from './components/research/creditrisk';
import MLandFinance from './components/research/MLandFinance';
import Banking from './components/research/banking';
import CBL from './components/research/CBL';
import CorporateFinance from './components/research/CorporateFinance';
import Municipalfinance from './components/research/municipalfinance';
import RealEstate from './components/research/realestate';
import Smallbusiness from './components/research/smallbusiness';
import Crypto from './components/research/crypto';
import QCF from './components/qcf/qcf';
import data from './components/data/data';
import FeedbackForm from './components/contactus/contactus';
import Partners from './components/partners/partners';
import News from './components/news/news';
import Events from './components/events/events';
import People from './components/people/people';
import Footer from './components/footer/footer';
import PPP from './components/research/PPP';
import CyberSecurity from './components/research/cybersecurity';
import Bankruptcy from './components/research/bankruptcy_viz';
import JoinUs from './components/join/join';
import ScrollToTop from './components/ScrollToTop';
import Projects from './components/projects/projects';

class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <ScrollToTop />
          <Header />
          <div style={{ paddingTop: 'var(--page-padding)' }}></div>
          <Switch>
            <Route exact path='/' component={About} />
            <Route path='/about' component={About} exact />
            <Route path='/people' component={People} exact />
            <Route path='/research' component={Research} exact />
            <Route path='/partners' component={Partners} exact />
            <Route path='/data' component={data} exact />
            <Route path='/join' component={JoinUs} exact />
            <Route path='/contactus' component={FeedbackForm} exact />
            <Route path='/research/covid/ppp' component={PPP} exact />
            <Route path='/research/creditrisk/bankruptcy' component={Bankruptcy} exact />
            <Route path='/publications' component={Publications} exact />
            <Route path='/events' component={Events} exact />
            <Route path='/news' component={News} exact />
            <Route path="/eLearning" component={Projects} exact />

          </Switch>
          <Footer />
        </HashRouter>
      </div>
    );
  }
}

export default App;
