import React from 'react';
import CorporateFinanceresearchlist from './CorporateFinanceresearchlist';
import { useQuery } from '@apollo/client';
import { researchList, updateResearchList } from '../../store';
import { getResearchList } from './researchService';


const CorporateFinanceresearchcardlist = () => {
    const { loading, error, data } = useQuery(getResearchList, { skip: researchList.length !== 0 })
    if (loading || error) return ""
    if (data) {
        updateResearchList(data.allResearch)
    }
    return (
        <CorporateFinanceresearchlist ResearchArray={researchList} />
    )
}

export default CorporateFinanceresearchcardlist;