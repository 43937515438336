// useScreenResize.js
import { useState, useEffect } from 'react';

function useScreenResize(navbarRef) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 966);
    const [navbarHeight, setNavbarHeight] = useState(0);

    useEffect(() => {
        if (navbarRef && navbarRef.current) {
            setNavbarHeight(navbarRef.current.clientHeight);
        }

        const handleResize = () => setIsMobile(window.innerWidth <= 966);
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [navbarRef]);

    return { isMobile, navbarHeight };
}

export default useScreenResize;
