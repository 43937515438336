import React, { useState, useEffect, useRef } from 'react';
import { Navbar, NavLink, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../images/GTOneLine_White.svg';
import useScreenResize from '../common/useScreenResize';


function Header() {

    const navbarRef = useRef(null);
    const { isMobile, navbarHeight } = useScreenResize(navbarRef);
    const location = useLocation();

    const navItems = [
        { label: 'About', path: '/' },
        { label: 'People', path: '/people' },
        { label: 'Research', path: '/research' },
        { label: 'Partners', path: '/partners' },
        { label: 'Join', path: '/join' },
        { label: 'Projects', path: '/eLearning' },
        { label: 'Data', path: '/data' },
        { label: 'Contact Us', path: '/contactus' },
    ];

    const WebNav = () => (
        <Nav style={styles.headerTexts}>
            {navItems.map((item, idx) => (
                <Link
                    key={idx}
                    to={item.path}
                    style={location.pathname === item.path ? {...styles.link, ...styles.activeLink} : styles.link}
                >
                    {item.label}
                </Link>
            ))}
        </Nav>
    );

    const DropDownNav = () => (
        <div style={styles_mobile.dropdownBox}>
            <Nav style={styles_mobile.dropdownNav}>
                {navItems.map((item, idx) => (
                    <React.Fragment key={idx}>
                        <NavLink style={location.pathname === item.path ? styles_mobile.dropdownText_active : styles_mobile.dropdownText} eventKey={idx + 1}>
                            <Link to={item.path}><strong>{item.label}</strong></Link>
                        </NavLink>
                        <div style={styles_mobile.dropdownDivider}></div>
                    </React.Fragment>
                ))}
            </Nav>
        </div>
    );

    return (
        <div>
            <Navbar collapseOnSelect={true} className="fixed-top" expand="lg" style={styles.headerGold}>
                <Navbar.Brand>
                <Link to='/' style = {styles.link}>
                    <img src = {logo}  height="80" style = { isMobile ? null : { paddingLeft:"50px" }}/>
                    </Link>
                </Navbar.Brand>
                {isMobile ?
                    <div>
                <Navbar.Toggle style = {{ backgroundColor:"white", marginRight:"10px"}}/> 
                        <Navbar.Collapse >
                    <DropDownNav/>
                        </Navbar.Collapse>
                    </div>
                    : null}
            </Navbar>

        <Navbar collapseOnSelect={true} className="fixed-top" expand="lg" style={{...styles.headerWhite, top: '80px'}}>
                <div style={styles.navbarContent} ref={navbarRef}>
                    <Navbar.Brand>
                        {isMobile ?
                            <div style={styles_mobile.navbarTitle_mobile}>
                                <div>Financial Services</div>
                                <div>Innovation Lab</div>
                            </div>
                            :
                            <div style={styles.navbarTitle}>
                                <div>Financial Services Innovation Lab</div>
                            </div>

                        }

                        <div style={styles.navbarDivider}></div>
                    </Navbar.Brand>
                </div>
            </Navbar>

            {isMobile ? null :
            <Navbar collapseOnSelect={true} className="fixed-top" expand="lg" style={{...styles.headerWhite, top: `${80 + navbarHeight}px`}}>
                <WebNav/>
                </Navbar>
            }
        </div>
);}

const styles_mobile = {
    navbarTitle_mobile: {
        height: "75px",
        color: '#003057',
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'left',
        fontSize: '30px',
        margin: '5px 0 10px 30px',
        lineHeight: 'normal',
        fontWeight: 700,
    },
    dropdownBox: {
        position: 'fixed',
        right: '0px',
        marginTop: "25px",
        borderRadius: "0px 0px 15px 15px",
        background: "white",
        zIndex: 2000,
    },

    dropdownNav: {
        display: 'flex',
        flexDirection: "column",
        padding: '10px 70px',
    },

    dropdownText: {
        textAlign: 'right',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '45px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        color: "#003057",
        paddingBottom: "13px",
        paddingTop: "16px",
    },

    dropdownText_active: {
        textAlign: 'right',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '45px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        color: "#003057",
        paddingBottom: "13px",
        paddingTop: "16px",
        color: '#B3A369',
    },

    dropdownDivider: {
        bottom: '0px',
        border: '1px solid #B3A369',
        width: '259px',
    },
};

const styles = {
    headerGold: {
        position: 'fixed',
        padding: '0',
        backgroundColor: '#B3A369',
        height: '80px'
    },
    headerWhite: {
        position: 'fixed',
        margin: '0',
        padding: '0',
        backgroundColor: 'white',
        zIndex: 1000,
    },
    navbarContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',
        paddingTop: '30px',
    },
    navbarTitle: {
        color: '#003057',
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'left',
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: 'normal',
        marginLeft: '80px',
    },

    navbarDivider: {
        position: 'absolute',
        width: '100%',
        border: '1px solid #B3A369',
    },
    headerTexts: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '29px',
        marginLeft: '80px',
        paddingTop: '11px',
        fontSize: '25px',
      paddingBottom:'37px',
    },
    link: {
        marginRight: '25px',
        fontWeight: 300,
        color: '#003057',
        textDecoration: 'none'
    },
    activeLink: {
        color: '#B3A369',
        fontWeight: 600,
    },
};

export default Header;
