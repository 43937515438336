import { gql } from '@apollo/client'

export const getNews = gql`{
    allNews {
        abstract
        id
        image {
          url
        }
        researchName
        researchWebsite
      }
}
`