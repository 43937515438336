import React from 'react';
import Smallbusinesssummarylist from './smallbusinesssummarylist';
import { useQuery } from '@apollo/client';
import { researchList, updateResearchList } from '../../store';
import { getResearchList } from './researchService';

const Smallbusinesssummarycardlist = () => {
    const { loading, error, data } = useQuery(getResearchList, { skip: researchList.length !== 0 })
    if (loading || error) return ""
    if (data) {
        updateResearchList(data.allResearch)
    }
    return (
        <Smallbusinesssummarylist ResearchArray={researchList} />
    )
}

export default Smallbusinesssummarycardlist;