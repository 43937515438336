import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll'
import MLresearchcardlist from './MLresearchcardlist';
import MLsummarycardlist from './MLsummarycardlist';
import {Button} from 'react-bootstrap';
import machine_learning from '../../images/artificial-intelligence-3382507_1920.jpg';
import './research.css';

class MLandFinance extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img class = "image-research" src={machine_learning} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                        <h1><b>ML and NLP in Finance</b></h1>
                        <h6 align = "justify">
                        The lab conducts research on the application of ML and NLP in finance. Some of the topics that the lab and affiliated researchers are currently working under this theme include textual analysis of corporate filings and earnings conference calls, application of ML models for predicting stock returns and to assess credit risk
                        </h6>
                    </div>
                </div>

                <MLsummarycardlist />

                <br/>

                <MLresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>

            </div>
            );
  }
}

export default MLandFinance;