import React, {Component} from 'react';
import Newscardlist from './eventscardlist';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask} from "mdbreact";
import about_1 from '../../images/risk_event.jpg';
import about_2 from '../../images/about_2.jpg';
import about_3 from '../../images/about_3.jpg';
import {Link} from 'react-router-dom';
import {Card, Button, Container,Row,Col} from 'react-bootstrap';
import {Link as Link1,Element, animateScroll as scroll} from 'react-scroll'

import './events.css'

class Events extends Component{
    scrollToTop() {
      scroll.scrollToTop();
    }
    render(){
    	return(
    		<div className = "news">  
          <br/>
          <br/>
          
          <MDBCarousel activeItem={1} length={1} showControls={true} showIndicators={true} className="z-depth-1">
                  <MDBCarouselInner>
                    <MDBCarouselItem itemId="1">
                      <MDBView>
                        <img
                          className="d-block w-100 eventimg" 
                          src = {about_1}
                          alt=""
                        />
                        <MDBMask overlay="black-light" />
                      </MDBView>
                      {/* <MDBCarouselCaption >
                        <div className="text-block-about">
                          <div className="CL_text_about">
                            <h3 className="h3-responsive"><b>About Us</b></h3>
                            <h6 align = "justify">
                              Georgia Tech’s Financial Services Innovation Lab is located in the heart of the Technology Square Innovation ecosystem. The FinTech lab aims to be a hub for finance education, research and industry in the Southeast. The lab acts as a platform to connect and bring together faculty and students across Georgia Tech with the financial services industry and FinTech entrepreneurs. 
                            </h6>
                            <p>
                                <Button size = "sm" variant="warning"><Link to='/people'>Read more</Link></Button>{' '}
                            </p>
                          </div>
                        </div>
                      </MDBCarouselCaption> */}
                    {/* </MDBCarouselItem>
                    <MDBCarouselItem itemId="2">
                      <MDBView>
                        <img
                          className="d-block w-100 eventimg"
                          src={about_2}
                          alt=""
                        />
                        <MDBMask overlay="black-strong" />
                      </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="3">
                      <MDBView>
                        <img
                          className="d-block w-100 eventimg"
                          src={about_3}
                          alt=""
                        />
                        <MDBMask overlay="black-strong" />
                      </MDBView> */}
                    </MDBCarouselItem>
                  </MDBCarouselInner>
                </MDBCarousel>

          <br/>

          <Newscardlist /> 

          {/* <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '} */}
          {/* <br/> */}
          <br/>

        </div>
      		);
    }
}

export default Events;