import React from 'react';
import { Card, Container, Row, Col, Carousel } from 'react-bootstrap';
import { Element } from 'react-scroll'
import 'tachyons';

const researchcard = ({ elementName, researchName, researchWebsite, researchAuthors, imageList, abstract, additionalContent }) => {
    return (
        <div>
            <Card>
                <Element name={elementName} className="element">
                    <Card.Header>
                        <div>
                            <h4 style={{ display: 'inline-block' }}>
                                <b> {researchName} </b>
                            </h4>
                            <a style={{ color: 'blue', display: 'inline-block', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer" href={researchWebsite}>[Link]</a>
                        </div>
                        <h5>
                            {researchAuthors.map((author) => {
                                return <a style={{ textDecoration: 'none', color: '#000000' }} target="_blank" rel="noopener noreferrer" href={author.website}>{author.name}; </a>
                            })}
                        </h5>
                    </Card.Header>
                </Element>
                <Card.Body>
                    <Row>
                        <Col xs={12} md={12} lg={4}>
                            <Carousel controls='false' indicators='false'>
                                {imageList.map((image) => {
                                    return <Carousel.Item>
                                        <img
                                            height="300px"
                                            className="d-block w-100"
                                            src={image.url}
                                            alt=""
                                        />
                                    </Carousel.Item>
                                })}
                            </Carousel>
                        </Col>

                        <Col xs={12} md={12} lg={8}>
                            <Container>
                                <p align="justify">
                                    <strong>Abstract: </strong>
                                    {abstract}<br /><br />
                                    {additionalContent.map(content => {
                                        return <div>
                                            <strong>{content.title}</strong>
                                            {content.body}
                                            < br />
                                        </div>
                                    })}
                                </p>
                            </Container>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}

export default researchcard;