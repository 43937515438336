const TYPE_HEADING_MAP = {
    Industry_Associates: "Industry Associates",
    Faculty_Associates: "Faculty Associates",
    Postdoc : "Postdoctoral Fellows" ,
    Current_PhD: "Current PhD Students",
    Current_Masters: "Current Masters Students",
    Masters_Alumni: "Masters Alumni",
    Research_Affliate: "Research Affiliates",
    PhD_Alumni: "PhD Alumni",
    Current_Undergrad: "Undergrad Students",
    Undergrad_Alumni: "Undergrad Alumni"
}

const TYPE_SORTING_MAP = {
    Industry_Associates: "Rank-Based-Sorting",
    Faculty_Associates: "Rank-Based-Sorting",
    Current_PhD: "Rank-Based-Sorting",
    Postdoc : "Rank-Based Sorting" ,
    Current_Masters: "Alphabetical Sorting",
    Masters_Alumni: "Alphabetical Sorting",
    Research_Affliate: "Alphabetical Sorting",
    PhD_Alumni: "Rank-Based-Sorting",
    Current_Undergrad: "Alphabetical Sorting",
    Undergrad_Alumni: "Alphabetical Sorting"  
}

const TYPE_HEADING_PRIORITY = ['Industry_Associates', 'Faculty_Associates', 'Postdoc' , 'Current_PhD', 'PhD_Alumni', 'Research_Affliate', 'Current_Masters', 'Masters_Alumni',
     'Current_Undergrad', 'Undergrad_Alumni']

export const getHeadingFromType = (type) => {
    return TYPE_HEADING_MAP[type]
}

export const getPriorityFromType = (type) => {
    return TYPE_HEADING_PRIORITY.indexOf(type)
}

export const getSortMethodByType = (type) => {
    return TYPE_SORTING_MAP[type]
}

