import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { getNews } from '../news/newsService';
import NewsCards from './about_news_card';
import useScreenResize from '../common/useScreenResize';
function AboutNewsList() {
    const { loading, error, data } = useQuery(getNews);
    const navbarRef = useRef(null);
    const { isMobile, navbarHeight } = useScreenResize(navbarRef); 

    if (loading || error)   
        return ""
    const slicedNewsArray = isMobile ? data.allNews.slice(0, 1): data.allNews.slice(0, 4);
    return (
        slicedNewsArray.map((news, i) => {
            return (
                <div>
                    <NewsCards
                        key={i}
                        header={news.researchName}
                        description={news.abstract}
                        pic={news.image}
                        newsLink={news.researchWebsite}
                    />
                    <br />
                </div>
            );
        })
    )

}

export default AboutNewsList;
