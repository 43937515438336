import React, { Component } from 'react';
import { Card, Container, Row, Col, ListGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './research.css';
import './PPP.css'
import myData from './Bankruptcy_viz.json';
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

class Bankruptcy extends Component {
    render() {
        return (
            <div>
                <br />
                <br />
                <br />
                <Container fluid>
                    <Card.Header>
                        <h2><b>United States Bankruptcy Analysis 1964-2019</b></h2>
                    </Card.Header>
                    <br />

                    <Card.Header>
                        <h5>Bankruptcies by Age, Sector and Market Capitalization</h5>
                    </Card.Header>

                    <Plot className="CL_PPP_bar_plot"
                        data={
                            myData['plotly_data_23']['data']
                        }
                        layout={myData['plotly_data_23']['layout']}
                    />
                    <Card.Text>Note: Limited to Companies for which information is available in CRSP Monthly Stock File(MSF)</Card.Text>
                    <Row>
                        <Col xs={12} md={12} xl={6}>
                            <Card border="light">
                                <Card.Header>
                                    Bankruptcies over time
                                </Card.Header>
                                <div align='center'>
                                    <Plot
                                        data={
                                            myData["plotly_data_19"]['data']
                                        }
                                        layout={myData["plotly_data_19"]['layout']}
                                    />
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <Card border="light">
                                <Card.Header>
                                    Sector wise bankruptcy
                                </Card.Header>
                                <div align='center'>
                                    <Plot
                                        data={
                                            myData["plotly_data_20"]['data']
                                        }
                                        layout={myData["plotly_data_20"]['layout']}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} xl={6}>
                            <Card border="light">
                                <Card.Header>
                                    Bankruptcies by state
                                </Card.Header>
                                <div id='divPlotly_21' align='center'></div>
                                <Helmet>
                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                    <script>Plotly.react('divPlotly_21', plotly_data_21.data, plotly_data_21.layout);</script>
                                </Helmet>
                                <Card.Text>Note: Limited to Companies for which state information is available in COMPUSAT</Card.Text>
                            </Card>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <Card border="light">
                                <Card.Header>
                                    Bankruptcy as percentage(%) of active firms in CRSP that year
                                </Card.Header>
                                <div align='center'>
                                    <Plot
                                        data={
                                            myData["plotly_data_22"]['data']
                                        }
                                        layout={myData["plotly_data_22"]['layout']}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Card className="CL_data_outer" bg="light" text="dark" style={{ width: '100%' }}>
                        <Card.Header style={{ fontWeight: "600" }} as="h5"> Data Sources </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <ListGroup>
                                    <ListGroup.Item>Bankruptcy Data: <a style={{ fontWeight: "600" }} target="_blank" rel="noopener noreferrer" href="https://fintech.gatech.edu/#/data"> Financial Services Innovation Lab, Georgia Tech </a></ListGroup.Item>
                                    <ListGroup.Item>CRSP: <a style={{ fontWeight: "600" }} target="_blank" rel="noopener noreferrer" href="https://wrds-www.wharton.upenn.edu/"> Wharton Research Data Services </a> </ListGroup.Item>
                                    <ListGroup.Item>COMPUSTAT: <a style={{ fontWeight: "600" }} target="_blank" rel="noopener noreferrer" href="https://wrds-www.wharton.upenn.edu/"> Wharton Research Data Services </a> </ListGroup.Item>
                                    <ListGroup.Item>Note: Age is calculated based on time from first year in CRSP Monthly Stock File(MSF) to going bankrupt. Market capitalization is calculated from last month data available in CRSP Monthly Stock File(MSF). Sector classification is based on Global Industry Classification Standard(GICS) available in COMPUSTAT data. State information is collected from COMPUSTAT data. </ListGroup.Item>
                                </ListGroup>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Button size='sm' variant="warning"><Link to='/research/'>Go Back</Link></Button>{' '}
                    <br />
                    <br />
                </Container>
            </div>
        );
    }
}

export default Bankruptcy;