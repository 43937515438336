import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import {Button} from 'react-bootstrap';
import CBLsummarycardlist from './CBLsummarycardlist';
import CBLresearchcardlist from './CBLresearchcardlist';
import CBLimg from '../../images/sign-pen-business-document-48148 (1).jpg';
import './research.css';

class CBL extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img class = "image-research" src={CBLimg} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                        <h1><b>Covenants in Bond and Loan Contracts</b></h1>
                        <h6 align = 'justify'>
                        Covenants are ubiquitous in corporate bonds and bank loan contracts. The lab conducts on many aspects of covenants: on the determinants of the incidence of covenants in corporate bonds and bank loan contracts, on the factors that determine the covenant choice and the ex post impact of technical covenant violations on the borrower.
                        </h6>
                    </div>
                </div>

                <br/>

                <CBLsummarycardlist />

                <br/>

                <CBLresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>
                    
            </div>
            );
  }
}

export default CBL;