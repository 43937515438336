import React, { useState, useEffect } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { Element, animateScroll as scroll } from 'react-scroll'
import '../../App.css';
import '../research/research.css'
import { Link as Link1 } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getResearchList } from '../research/researchService';
import { researchList, updateResearchList } from '../../store';
import Summarycard from '../research/summarycard';

function Research() {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const researchFilterList = [
        {
            name: 'Banking', selected: true, researchField: 'Banking'
        }, {
            name: 'Fintech and Innovation', selected: false, researchField: 'FinTech'
        }, {
            name: 'Responsible Finance', selected: false, researchField: 'Responsible Finance'
        }, {
            name: 'Credit Risk', selected: false, researchField: 'Credit Risk'
        }, {
            name: 'ML and NLP', selected: false, researchField: 'ML and NLP in Finance'
        }, {
            name: 'Real Estate', selected: false, researchField: 'Real Estate'
        }, {
            name: 'Household Finance', selected: false, researchField: 'Household Finance'
        }, {
            name: 'Covenants in Bond and Loan Contracts', selected: false, researchField: 'Covenants in Bond and Loan Contracts'
        }, {
            name: 'Small Business', selected: false, researchField: 'Small Business'
        }, {
            name: 'Municipal Finance', selected: false, researchField: 'Municipal Finance'
        }, {
            name: 'Corporate Finance', selected: false, researchField: 'Corporate Finance'
        }, {
            name: 'Crypto and Blockchain', selected: false, researchField: 'Crypto Currencies and Blockchain'
        }, {
            name: 'Stock Return and Asset Management', selected: false, researchField: 'Stock Returns and Asset Management'
        }]
    const [researchOption, setResearchOption] = useState(researchFilterList);
    const [currentResearchField, setCurrentResearchField] = useState('Banking')
    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);
    const { loading, error, data } = useQuery(getResearchList, { skip: researchList.length !== 0 })
    if (loading || error) return ""
    if (data) {
        updateResearchList(data.allResearch)
    }
    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    const updateSelection = (filter) => {
        const newResearchList = researchOption.map(res => {
            return {
                name: res.name,
                selected: res.name === filter.name,
                researchField: res.researchField
            }
        })
        setResearchOption(newResearchList)
        setCurrentResearchField(filter.researchField)
    }

    return (
        <div className="research-main-page">
            <div className="research-filter-container">
                {researchOption.map(option => {
                    return <div className={
                        `research-filter ${option.selected ? "research-filter-selected" : ""}`}
                        onClick={() => updateSelection(option)}> {option.name}
                    </div>
                })}
            </div>
            <Container fluid>
                <div class="research-summary-container">
                    {
                        researchList.map((research, i) => {
                            if (research.researchField === currentResearchField)
                                return (
                                    <Summarycard
                                        key={i}
                                        id={research.id}
                                        research={research}
                                    />
                                );
                        })
                    }
                </div>
            </Container>
        </div>
    );
}

export default Research;