import React, { Component } from 'react';
import {Element, animateScroll as scroll} from 'react-scroll';
import coronavirus from '../../images/coronavirus_1.jpg';
import COVIDresearchcardlist from './covidresearchcardlist';
import COVIDsummarycardlist from './covidsummarycardlist';
import {Card, Container,Row,CardDeck,Button,Carousel,Col} from 'react-bootstrap';
import covid1 from '../../images/covid1.png';
import covid5 from '../../images/covid5.png';
import covid9 from '../../images/covid9.png';
import PPP1 from '../../images/Noofloans.PNG';
import PPP2 from '../../images/bargraph.PNG';
import PPP3 from '../../images/noofjobs.PNG';
import Employment1 from '../../images/GAJobPostings.png';
import Employment2 from '../../images/GAJobPostingsIndustry.png';
import Employment3 from '../../images/GAJobPostingsOccupation.png';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useMediaQuery } from 'react-responsive'
import './research.css';
 
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 650 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 649 })
    return isMobile ? children : null
  }

class COVID extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div >
                    <img  class = "image-research" src={coronavirus} alt="" style={{width:'100%'}}/>
                        <div class="text-block-research">
                            <h1><b>COVID-19</b></h1>
                            <h6 align = "justify">The COVID-19 pandemic and subsequent economic shutdown  have wreaked havoc on global economies. A group of Georgia Tech students and faculty assisted by the Financial Services Innovation Lab at Georgia Tech and sponsored by the Speedwell Foundation, have created a website to document the far-ranging effects of the virus on the health and well being of the statewide economy and its residents.</h6>
                        </div>
                </div>

                <COVIDsummarycardlist />

                <br/>

                <Container fluid>
                    <Card>
                    <Element name="GTCER" className="element" >
                        <Card.Header>
                            <h4><b>Georgia Tech COVID-19 Economic Research</b></h4>
                        </Card.Header>
                    </Element>
                        <Card.Body>
                            <Row>
                                <Container fluid>
                                <p align = 'justify'>
                                The COVID-19 pandemic and subsequent economic shutdown  have wreaked havoc on global economies. We, a group of Georgia Tech students, supported by faculty, have created this web site to document the far-ranging effects of the virus on the health and well being of the statewide economy and its residents. What follows are descriptions of the effect of the pandemic on multiple sectors of the Georgia economy including employment, healthcare, sales tax, transportation, air travel, hotels, public companies, municipal bonds and real estate. We plan to update this data regularly, daily in some cases, to provide state residents and other interested parties with the knowledge to facilitate understanding and decision making in the current and post-Covid environment. Our project is under the guidance of the Financial Services Innovation Lab at Georgia Tech and sponsored by the Speedwell Foundation.
                                </p>
                                </Container>
                            </Row>
                            <Row>
                                <CardDeck>
                                    <Card>
                                        <Card.Header>
                                            <h5>Unemployment</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src={covid1} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Restaurants</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src= {covid5} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Municipal Bonds</h5>
                                        </Card.Header>
                                        <Card.Img variant="top" src={covid9} />
                                    </Card>
                                </CardDeck>
                            </Row>
                            <br/>
                            <p align = 'left'>
                                <Button size = 'sm' target="_blank" rel="noopener noreferrer" href = "https://gacovid19.org/" variant="warning">Read more</Button>{' '}
                            </p>
                        </Card.Body>
                    </Card>

                    <br/>

                    <Card>
                        <Element name="PPPLAG" className="element" >
                        <Card.Header>
                            <h4><b>Paycheck Protection Program(PPP) Loan Analysis for Georgia</b></h4>
                        </Card.Header>
                        </Element>
                        <Card.Body>
                            <Row>
                                <Container fluid>
                                    <p align = 'justify'>
                                    To help small business during the coronavirus pandemic, the CARES Act created the Paycheck Protection Program. This was established in addition to the Small Business Administration's EIDL program, which provides loans to small businesses affected by declared disasters. Small businesses are those with less than 500 employees and make up about 99.6% of all Georgia businesses.
                                    The Small Business Administration released a PPP Report with loan approval data through 6/27. There are now 152,382 loans and $14,365,898,579 approved in round 2 of PPP funding in the state of Georgia. 
                                    </p>
                                </Container>
                            </Row>
                            <Row>
                                <CardDeck>
                                    <Card>
                                        <Card.Header>
                                            <h5>Countywise number of PPP loans </h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '400px'}} variant="top" src={PPP1} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Industry/Business type disribution of loans</h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '400px'}} variant="top" src= {PPP2} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Number of loans</h5>
                                        </Card.Header>
                                        <Card.Img style = {{height: '400px'}} variant="top" src={PPP3} />
                                    </Card>
                                </CardDeck>
                            </Row>
                            <br/>
                            <p align = 'left'>
                                <Button size = 'sm' variant="warning"><Link to='/research/covid/ppp'>Read more</Link></Button>{' '}
                            </p>
                        </Card.Body>
                    </Card>
        
                    <br/>
                    
                    <Card>
                        <Element name="ICGE" className="element" >
                        <Card.Header>
                            <h4><b>Impact of COVID-19 on Georgia's Employment</b></h4>
                        </Card.Header>
                        </Element>
                        <Card.Body>
                           
                            <Row>
                                <CardDeck>
                                    <Card>
                                        <Card.Header>
                                            <h5>Employment - 2019 vs 2020 </h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text align = 'justify'>
                                            •	The number of job postings in January 2020 is 20% more than the number in January 2019. <br/>
                                            •	The number of job postings in Apr, May 2020 drops by more than 30% compared to the number in Feb 2020. <br/>
                                            •	The number of posting recovers in June 2020. The number is higher than the number in Jun 2019, but is still lower than the number at the beginning of this year. <br/>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img style = {{height: '400px'}} variant="top" src={Employment1} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Industrywise Employment</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text align = 'justify'>
                                        •	“Ironically”, the number of job postings drops for the health care and social assistance industry in a health crisis. <br/>
                                        •	Accommodation and food service has the highest unemployment rate in Jun 2020 (26.6%) based on BLS, however, its job postings get back to the level at the beginning of this year as employers try to hire workers back after reopening.<br/>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img style = {{height: '400px'}} variant="top" src= {Employment2} />
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>Occupationwise Employment</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text align = 'justify'>
                                        •	The lowed labor demand (less postings) is almost across the board. Even these occupations which are less affected by the lockdown (e.g., computer and mathematical) see big drop in the number of postings.<br/>
                                        •	Interestingly but not surprisingly, transportation and material moving is the least affected occupation.<br/>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Img style = {{height: '400px'}} variant="top" src={Employment3} />
                                    </Card>
                                </CardDeck>
                            </Row>
                        </Card.Body>

                        <Desktop>
                            <Row>
                                <Col xs={12} md = {12} lg = {6}>
                                    <Card>
                                        <Card.Header>
                                            <h5>Georgia Job Postings January 2020 onwards</h5>
                                        </Card.Header>
                                        <div id='divPlotly_12'></div>
                                        <Helmet >
                                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                            <script>Plotly.react('divPlotly_12', plotly_data_12.data, plotly_data_12.layout);</script>
                                        </Helmet>
                                    </Card>
                                </Col>
                                
                                <Col xs={12} md = {12} lg = {12} xl = {6}>
                                    <Card>
                                        <Card.Header>
                                            <h5>Georgia Job Postings Monthy January to June 2020</h5>
                                        </Card.Header>
                                        <Carousel fade = 'true' controls = 'false' interval = '2000' >
                                            <Carousel.Item>
                                                <div id='divPlotly_13'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_13', plotly_data_13.data, plotly_data_13.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_14'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_14', plotly_data_14.data, plotly_data_14.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_15'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_15', plotly_data_15.data, plotly_data_15.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_16'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_16', plotly_data_16.data, plotly_data_16.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_17'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_17', plotly_data_17.data, plotly_data_17.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_18'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_18', plotly_data_18.data, plotly_data_18.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                        </Carousel>
                                    </Card>
                                </Col>
                            </Row>
                        </Desktop>

                        <Mobile>
                            <Row>
                                    <Card>
                                        <Card.Header>
                                            <h5>Georgia Job Postings January 2020 onwards</h5>
                                        </Card.Header>
                                        <div id='divPlotly_xs_12'></div>
                                        <Helmet >
                                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                            <script>Plotly.react('divPlotly_xs_12', plotly_data_xs_12.data, plotly_data_xs_12.layout);</script>
                                        </Helmet>
                                    </Card>
                                </Row>
                                
                                <Row>
                                    <Card>
                                        <Card.Header>
                                            <h5>Georgia Job Postings Monthy January to June 2020</h5>
                                        </Card.Header>
                                        <Carousel fade = 'true' controls = 'false'>
                                            <Carousel.Item>
                                                <div id='divPlotly_xs_13'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_xs_13', plotly_data_xs_13.data, plotly_data_xs_13.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_xs_14'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_xs_14', plotly_data_xs_14.data, plotly_data_xs_14.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_xs_15'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_xs_15', plotly_data_xs_15.data, plotly_data_xs_15.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_xs_16'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_xs_16', plotly_data_xs_16.data, plotly_data_xs_16.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_xs_17'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_xs_17', plotly_data_xs_17.data, plotly_data_xs_17.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div id='divPlotly_xs_18'></div>
                                                <Helmet >
                                                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                                                    <script>Plotly.react('divPlotly_xs_18', plotly_data_xs_18.data, plotly_data_xs_18.layout);</script>
                                                </Helmet>
                                            </Carousel.Item>
                                        </Carousel>
                                    </Card>
                            </Row>
                        </Mobile>

                        <br/>
     
                    </Card>

                </Container>

                <br/>

                <COVIDresearchcardlist /> 

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>

            </div>
            );
  }
}

export default COVID;
