import React, { useState, useEffect, useRef } from 'react';
import useScreenResize from './useScreenResize';

function TitleTextBox({title, description, hasDivder}) {

    const navbarRef = useRef(null);
    const { isMobile, navbarHeight } = useScreenResize(navbarRef); 
    const appliedStyles = isMobile ? mobile_styles : styles;

    return (
        <div style={appliedStyles.myTextbox}>
            <h2 style={appliedStyles.myTitle}>{title}</h2>
            {description && <p style={appliedStyles.myDescription}>{description}</p>}
            {hasDivder ? <div style={appliedStyles.myDivider}></div> : null}
        </div>
    );
}


/* normal page styles */
const styles = {
    myTextbox: {
        padding: "25px 137px 0 137px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 0,
        
    },

    myTitle: {
        fontFamily: "'Roboto', sans-serif",
        color: "#54585A",
        textAlign: "center",
        fontSize: "35px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingBottom: "20px"
    },

    myDescription: {
        fontFamily: "'Roboto', sans-serif",
        color: "#000",
        textAlign: "justify",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        padding: "0px 137px ",
    },

    myDivider: {
        height: "2px",
        backgroundColor: "#54585A", 
        marginBottom: "10px",
        width: "100%",
    }
}

/* mobile page styles */
const mobile_styles = {
    myTextbox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 0,
    },

    myTitle: {
        fontFamily: "'Roboto', sans-serif",
        color: "#54585A",
        textAlign: "center",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingBottom: "20px",
        paddingTop: "40px",
    },

    myDescription: {
        fontFamily: "'Roboto', sans-serif",
        color: "#000",
        textAlign: "justify",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    myDivider: {
        height: "2px",
        backgroundColor: "#54585A", 
        marginBottom: "10px",
        width: "80%",
    }
}

export default TitleTextBox;
