import React from 'react';
import { useQuery } from '@apollo/client';
import { getResearchList } from '../research/researchService';
import ResearchCard from './about_research_card';

const AboutResearchList = ({ResearchArray}) => {
    const FinTechResearch = ResearchArray.filter(res => (res.researchField == 'FinTech' && res.hasResearch === 'FinTech'));
    const MLResearch = ResearchArray.filter(res =>(res.researchField === 'ML and NLP in Finance'));
    const slicedResearchArray = MLResearch.slice(0, 2).concat(FinTechResearch.slice(0, 2));


    return (
        slicedResearchArray.map((res, i) => {
            // if ((res.researchField ===  'ML and NLP in Finance') && (res.hasResearch === 'Banking'))
            return (
                <div>
                    <ResearchCard
                        key={i}
                        description={res.researchName}
                        imageList={res.image}
                        researchLink={res.researchWebsite}
                    />
                    <br />
                </div>
            );
        })
    )

}

export default AboutResearchList;
