import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Card, Container, Row, CardDeck } from 'react-bootstrap';
import 'tachyons';
import Researchcard from './researchcard';
import tag20181 from '../../images/tag_fintech_2018_1.png';
import tag20182 from '../../images/tag_fintech_2018_2.PNG';
import tag20183 from '../../images/tag_fintech_2018_3.PNG';
import tag20161 from '../../images/tag_fintech_2016_1.PNG';
import tag20162 from '../../images/tag_fintech_2016_2.PNG';
import tag20163 from '../../images/tag_fintech_2016_3.PNG';
import Bankruptcy1 from '../../images/Bankruptcy_1.PNG';
import Bankruptcy2 from '../../images/Bankruptcy_2.PNG';
import Bankruptcy3 from '../../images/Bankruptcy_3.PNG';

function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const getModalContent = (research) => {
    console.log(research)
    switch (research.researchName) {
        case "2018 State of Georgia's FinTech Ecosystem": {
            return <Card>
                <Card.Header>
                    <h4><b>2018 State of Georgia's FinTech Ecosystem</b></h4>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Container fluid>
                            <p align='justify'>
                                Georgia’s FinTech industry is inevitably associated
                                with the payments sector, given the state’s remarkable
                                strength in this area- hence the nickname Transaction
                                Alley. Georgia continues to boast seven of the
                                nine largest U.S. card acquirers, even after some
                                shakeup at the top of the leaderboard. Since
                                our last report, Atlanta-based Global Payments
                                completed its acquisition of Heartland Payment
                                Systems. More recently, Vantiv acquired Worldpay
                                (whose US headquarters are based in Atlanta), with
                                the combined entity continuing under the Worldpay
                                name and maintaining a local management structure.
                                Earlier in 2017 Vantiv also built upon its Atlanta-based
                                portfolio with the acquisition of Paymetric.
                            </p>
                        </Container>
                    </Row>
                    <Row>
                        <CardDeck>
                            <Card>
                                <Card.Header>
                                    <h5>Georgia FinTech Ecosystem</h5>
                                </Card.Header>
                                <Card.Img style={{ height: '300px' }} variant="top" src={tag20181} />
                            </Card>
                            <Card>
                                <Card.Header>
                                    <h5>Most critical FinTech initiatives</h5>
                                </Card.Header>
                                <Card.Img style={{ height: '300px' }} variant="top" src={tag20182} />
                            </Card>
                            <Card>
                                <Card.Header>
                                    <h5>Global financing volume into technology</h5>
                                </Card.Header>
                                <Card.Img style={{ height: '300px' }} variant="top" src={tag20183} />
                            </Card>
                        </CardDeck>
                    </Row>
                    <br />
                    <p align='left'>
                        <Button size='sm' target="_blank" rel="noopener noreferrer" href="https://www.fintechatlanta.org/assets/pdf/2017-tag-fintech-ecosystem-report.PDF" variant="warning">Read more</Button>{' '}
                    </p>
                </Card.Body>
            </Card>
        }
        case "2016 State of Georgia's FinTech Ecosystem": {
            return <Card>
                <Card.Header>
                    <h4><b>2016 State of Georgia's FinTech Ecosystem</b></h4>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Container fluid>
                            <p align='justify'>
                                Georgia’s financial technology industry is ready for its close-up. For years, the sector’s leading companies were content to play a behind the scenes role creating and running the critical infrastructure that
                                enables much of the world’s financial services, particularly payments. Since the financial crisis, however, several of these companies have recognized the value of taking more visible positions in advocacy on topics such as legislation and data security, engaging in public/private partnerships, etc.
                                The Georgia FinTech sector encompasses about 100 companies ranging from Fortune 500 bellwethers
                                to early stage start ups. There are firms whose time in the state dates to the 1800s (Equifax), several
                                that moved to Georgia at varying stages of their evolution (NCR, CheckFree, Groundfloor), one that
                                left Georgia only to return (First Data), and one that recently undertook a national search for a headquarters
                                location before determining it already had the ideal locale (Worldpay).
                            </p>
                        </Container>
                    </Row>
                    <Row>
                        <CardDeck>
                            <Card>
                                <Card.Header>
                                    <h5>Georgia-based FinTech Statistics</h5>
                                </Card.Header>
                                <Card.Img style={{ height: '300px' }} variant="top" src={tag20161} />
                            </Card>
                            <Card>
                                <Card.Header>
                                    <h5>Key Drivers of Tehnology Investment</h5>
                                </Card.Header>
                                <Card.Img style={{ height: '300px' }} variant="top" src={tag20162} />
                            </Card>
                            <Card>
                                <Card.Header>
                                    <h5>FinTech job functions to be emphasized</h5>
                                </Card.Header>
                                <Card.Img style={{ height: '300px' }} variant="top" src={tag20163} />
                            </Card>
                        </CardDeck>
                    </Row>
                    <br />
                    <p align='left'>
                        <Button size='sm' target="_blank" rel="noopener noreferrer" href="http://www.prism.gatech.edu/~schava6/TAGFinTechReport2016.pdf" variant="warning">Read more</Button>{' '}
                    </p>
                </Card.Body>
            </Card >
        }
        case "United States Bankruptcy Analysis": {
            return <Card>
                <Card.Header>
                    <h4><b>United States Bankruptcy Analysis</b></h4>
                </Card.Header>

                <Card.Body>
                    <Row>
                        <Container fluid>
                            <p align='justify'>
                                Companies file bankruptcy when they are unable to pay their debt. Between 1964 and 2019 more than 3900 public companies filed bankruptcy. We have analysed the bankruptcy data by sector, state, age and market capitalization over time.
                            </p>
                        </Container>
                    </Row>
                    <Row>
                        <CardDeck>
                            <Card>
                                <Card.Header>
                                    <h5>Bankruptcy over time</h5>
                                </Card.Header>
                                <Card.Img variant="top" src={Bankruptcy1} />
                            </Card>
                            <Card>
                                <Card.Header>
                                    <h5>Statewise Bankruptcy</h5>
                                </Card.Header>
                                <Card.Img variant="top" src={Bankruptcy2} />
                            </Card>
                            <Card>
                                <Card.Header>
                                    <h5>Sectorwise Bankruptcy</h5>
                                </Card.Header>
                                <Card.Img variant="top" src={Bankruptcy3} />
                            </Card>
                        </CardDeck>
                    </Row>
                    <br />
                    <p align='left'>
                        <Button size='sm' variant="warning"><Link to='/research/creditrisk/bankruptcy'>Read more</Link></Button>{' '}
                    </p>
                </Card.Body>
            </Card>
        }
        default: {
            return <Researchcard
                key={research.id}
                id={research.id}
                elementName={research.elementName}
                researchName={research.researchName}
                researchWebsite={research.researchWebsite}
                researchAuthors={research.researchAuthors}
                imageList={research.image}
                abstract={research.abstract}
                additionalContent={research.additionalContent}
            />
        }
    }
}

const Summarycard = ({ research }) => {
    const [openModal, setOpenModal] = useState(false)
    const ref = useRef(null);
    useOutsideAlerter(ref, () => { setOpenModal(false) });
    return (
        <div className=' dib br3 shadow pa3 ma2 bw2 cardtextstylingresearch'>
            <img className="cardimgstylingresearch" src={research.image[0].url} alt="" />
            <p align="justify"><b> {research.researchName} </b></p>
            <p align='left'>
                <Button variant="warning" size="sm" onClick={() => setOpenModal(true)}>Read more</Button>{' '}
            </p>
            {openModal ?
                <div className='research-modal-background-div'>
                    <div ref={ref} className='research-modal-container'>
                        {getModalContent(research)}
                    </div>
                </div>
                : ""}
        </div>
    )
}

export default Summarycard;