import React from 'react';
import Eventlist from './eventslist';
import { useQuery } from '@apollo/client';
import { getEvents } from './eventService';


const Eventscardlist = () => {
    const { loading, error, data } = useQuery(getEvents)
    if (loading || error) return ""
    return (
        <Eventlist EventArray={data.events} />
    )
}

export default Eventscardlist;