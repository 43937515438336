import React, {Component} from 'react';
import qcf_logo from '../../images/GT_QCF_Logo.png';
import qcf_background from '../../images/stock-1863880.jpg';
import './qcf.css'

class QCF extends Component{
    render(){
    	return(
				<div className = "qcf">
					<a target="_blank"  rel="noopener noreferrer" href = "https://www.scheller.gatech.edu/degree-programs/interdisciplinary-ms/quantitative-and-computational-finance/index.html">
						<img className = 'QCFimg' src={qcf_background} alt="Nature" style={{width:'100%'}} />
						<div class="text-block-qcf">
							<img className="CL_qcf_logo" src = {qcf_logo} alt="404"></img>	
						</div>
						<div class="text-block-qcf-text"> 
                            <h4><b>MASTER OF SCIENCE IN QUANTITATIVE AND COMPUTATIONAL FINANCE</b></h4>
								<br/>
                                <h6 align ="justify"> 
								The Master of Science degree program in Quantitative and Computational Finance (QCF) is 
								interdisciplinary between three of Georgia Tech's most prestigious units:   
								the Scheller College of Business, the H. Milton Stewart School of Industrial & Systems Engineering, and the School of Mathematics.
								The main objective of the Master of Science degree program in Quantitative and 
								Computational Finance at Georgia Tech is to provide students with the practical skills and theoretical 
								understanding they need to be leaders in the formulation, implementation and evaluation of the models 
								used by the financial sector to structure transactions, manage risk and construct investment strategies.
                                </h6>
								<h5 align='left'>
									<br/>
									2021 QuantNet : Top 10 in continental USA
									<br/>
									2020 QuantNet : Among the 5 programs in top 10 with 100% placement
									<br/>
									2020 Risk.net: Top 10 in North America
									<br/>
									2020 TFE Times: #9
									<br/>
									2019 Quantnet: 100% paid internships, 100% FT placement, 1.6 job offers per student
									<br/>
									2018 Quantnet: #10 in North America
									<br/>
								</h5>
                        </div>
						
					</a>
				</div>
    	);
    }
}

export default QCF;
