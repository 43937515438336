import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import {Button} from 'react-bootstrap';
import RealEstateresearchcardlist from './realestateresearchcardlist';
import RealEstatesummarycardlist from './realestatesummarycardlist';
import realestate from '../../images/home-real-estate-106399.jpg';
import './research.css';

class RealEstate extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div>
                <div >
                <br/>
                <br/>
                    <img class = "image-research"  src={realestate} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                    <h1><b>Real Estate</b></h1>
                    <h6 align = 'justify'>
                    Housing is a major component of total household wealth in the U.S., and at approximately $25 trillion,  accounts for more than a quarter of household net worth. Similarly, commercial real estate in the U.S. accounts for more than $16 trillion. The lab conducts research on both residential real estate markets and commercial real estate markets.
                    </h6>
                    </div>
                </div>
                
                <RealEstatesummarycardlist />

                <br/>

                <RealEstateresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>
                
            </div>
            );
  }
}

export default RealEstate;