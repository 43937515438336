import React from 'react';
import Newscard from './newscard';
import { Container } from 'react-bootstrap';

const Newslist = ({ ResearchArray }) => {
    return (
        <Container>
            {
                ResearchArray.map((news, i) => {
                    return (
                        <div>
                            <Newscard
                                key={i}
                                researchName={news.researchName}
                                researchWebsite={news.researchWebsite}
                                image1={news.image}
                                abstract={news.abstract}
                            />
                            <br />
                        </div>
                    );
                })
            }
        </Container>
    );
}

export default Newslist;