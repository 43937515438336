import React, {Component} from 'react';

class ResearchCard extends Component {
    render() {
        const { description, imageList, researchLink } = this.props;
        return (
            <div style={researchCards}>
                <img src={imageList[0].url} style={researchPicture}/>
                <p style={researchDescription}>{description}</p>
                <a href={researchLink}><button style={researchButton} >READ MORE</button></a>
            </div>
        );
    }
}

const researchCards = {
    display: "flex",
    flexDirection: "column",
    width: "250px",
    height: "350px",
    flexShrink: 0,
    backgroundColor: "rgba(214, 219, 212, 0.35)",
    border: "0px solid #000",
    borderRadius: "5px",
    margin: "auto",
}

const researchPicture = {
    paddingTop:"21px",
    border: "1px",
    alignSelf:"center",
    flexShrink: 0,
    width:"200px",
    height:"200px",
    borderRadius: "5px",
}

const researchDescription = {
    padding: "16px 25px 45px",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "normal",
    textAlign: "center",
    height: "26px",
}

const researchButton = {
    width: "93px",
    height: "33px",
    flexShrink: 0,
    fontWeight: 700,
    alignSelf: "center",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "12px",
    textAlign: "center",
    color: "#FFFFFF",
    background: "#003057",
    borderRadius: "5px",
}
export default ResearchCard;

