import React from 'react';
import { Card, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Element } from 'react-scroll'
import 'tachyons';

const Eventscard = ({ eventimage }) => {
    return (
        <div className=' dib br3 pa3 ma2 grow bw2'>
            <img className="eventcardimgstyling" src={eventimage.url} alt="" />
        </div>
    )
}

export default Eventscard;