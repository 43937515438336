import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll'
import Municipalfinanceresearchcardlist from './municipalfinanceresearchcardlist';
import Municipalfinancesummarycardlist from './municipalfinancesummarycardlist';
import { Button } from 'react-bootstrap';
import municipalfinanceimg from '../../images/aerial-architectural-design-architecture-buildings-373912.jpg';
import './research.css';

class Municipalfinance extends Component {
    scrollToTop() {
        scroll.scrollToTop();
    }
    render() {
        return (
            <div className="research">
                <br />
                <br />
                <div >
                    <img class="image-research" src={municipalfinanceimg} alt="" style={{ width: '100%' }} />
                    <div class="text-block-research">
                        <h1><b>Municipal Finance</b></h1>
                        <h6 align="justify">
                            The municipal debt market, at $3.8 trillion, is a signiﬁcant source of ﬁnancing for local governments in the U.S. The lab conducts research on local government finances and various factors impacting the cost of municipal financing.
                        </h6>
                    </div>
                </div>

                {/* <Municipalfinancesummarycardlist /> */}

                <br />

                <Municipalfinanceresearchcardlist />

                <Button size='sm' variant="warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br />
                <br />

            </div>
        );
    }
}

export default Municipalfinance;