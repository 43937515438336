import React, { Component } from 'react';
import { Card, Image, Row, Col, Button } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
import news_1 from '../../images/schellershot_online_1.jpg';
import news_2 from '../../images/VC_Pay_0720.jpg';
import news_3 from '../../images/Loudmouth-at-Podium-e1582041813821.jpg';
import news_4 from '../../images/virus-on-economy-graphic-h_1.jpg';
import news_5 from '../../images/engage-atdc-4798.jpeg';
import Newscardlist from './newscardlist';
import './news.css'

class News extends Component {
  scrollToTop() {
    scroll.scrollToTop();
  }
  render() {
    return (
      <div className="news">
        <br />
        <br />
        <div className="row no-gutters">
          <Col xs={12} md={12} lg={6} xl={6}>
            <a style={{ textDecoration: 'none', color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://www.scheller.gatech.edu/news-events/latest-news/2022/articles/georgia-tech-scheller-college-of-business-and-equifax-create-partnership-to-advance-inclusive-fintech-innovation.html">
              <Card className="bg-dark text-white noHover" >
                <Image style={{ width: '100%', height: '70.3vh' }} className="card-img-top" fluid src={news_1} alt="Card image" />
                <div class="text-block-card text-block-big-card">
                  <h6 align="justify">Financial Services Innovation Lab and Equifax Create Partnership to Advance Inclusive Fintech Innovation</h6>
                </div>
              </Card>
            </a>
          </Col>

          <Col xs={12} md={12} lg={6}>
            <Row className="no-gutters">
              <Col xs={6} md={6} lg={6} xl={6}>
                <a style={{ textDecoration: 'none', color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://www.institutionalinvestor.com/article/b1mpq7g4wp0dwc/Venture-Capital-Makes-Talent-Unaffordable-for-Start-Ups-Competitors">
                  <Card className="bg-dark text-white noHover">
                    <Image style={{ width: '100%', height: '35vh' }} class="card-img-top" fluid src={news_2} alt="Card image" />
                    <div class="text-block-card">
                      <h6 align="justify">Venture Capital Makes Talent Unaffordable for Start Ups’ Competitors</h6>
                    </div>
                  </Card>
                </a>
              </Col>
              <Col xs={6} md={6} lg={6} xl={6}>
                <a style={{ textDecoration: 'none', color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://www.cfo.com/investor-relations-banking-capital-markets/2020/02/extreme-language-in-earnings-calls-linked-to-outperformance/">
                  <Card className="bg-dark text-white noHover">
                    <Image style={{ width: '100%', height: '35vh' }} class="card-img-top" fluid src={news_3} alt="Card image" />
                    <div class="text-block-card">
                      <h6 align="justify">‘Extreme Language’ in Earnings Calls Linked to Outperformance</h6>
                    </div>
                  </Card>
                </a>
              </Col>

              <Col xs={6} md={6} lg={6} xl={6}>
                <a style={{ textDecoration: 'none', color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://news.gatech.edu/2020/07/23/tracking-effects-covid-19-georgias-economy">
                  <Card className="bg-dark text-white noHover">
                    <Image style={{ width: '100%', height: '35vh' }} class="card-img-top" fluid src={news_4} alt="Card image" />
                    <div class="text-block-card">
                      <h6 align="justify">Tracking the Effects of Covid-19 on Georgia’s Economy</h6>
                    </div>
                  </Card>
                </a>
              </Col>
              <Col xs={6} md={6} lg={6} xl={6}>
                <a style={{ textDecoration: 'none', color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://hypepotamus.com/people/tech-square-ventures-founder-blake-patton-shares-details-of-firms-new-75-million-fund/">
                  <Card className="bg-dark text-white noHover">
                    <Image style={{ width: '100%', height: '35vh' }} class="card-img-top" fluid src={news_5} alt="Card image" />
                    <div class="text-block-card">
                      <h6 align="justify"> Tech Square Ventures Founder Blake Patton shares details of firm's new $75 million fund</h6>
                    </div>
                  </Card>
                </a>
              </Col>
            </Row>
          </Col>
        </div>

        <br />

        <Newscardlist />

        <Button size='sm' variant="warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
        <br />
        <br />

      </div>
    );
  }
}

export default News;