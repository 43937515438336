import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import {Button} from 'react-bootstrap';
import Tradingresearchcardlist from './tradingresearchcardlist';
import Tradingsummarycardlist from './tradingsummarycardlist';
import trading from '../../images/black-blue-and-red-graph-illustration-186461.jpg';
import './research.css';

class Trading extends Component{
    scrollToTop() {
        scroll.scrollToTop();
      }
    render(){
        return(
            <div className = "research">
                <br/>
                <br/>
                <div>
                    <img class = "image-research" src={trading} alt="" style={{width:'100%'}}/>
                    <div class="text-block-research">	
                        <h1><b>Stock Returns and Asset Management</b></h1>
                        <h6 align = "justify">
                            The lab conducts research on the cross-section of stock returns, developing trading strategies and understanding the source of their performance. Related topics include the behavioral bias of investors and other topics relevant to the asset management industry. 
                        </h6>
                    </div>
                </div>

                <Tradingsummarycardlist />
                
                <br/>

                <Tradingresearchcardlist />

                <Button size = 'sm' variant = "warning" onClick={this.scrollToTop}>Scroll to Top</Button>{' '}
                <br/>
                <br/>
                    
            </div>
            );
  }
}

export default Trading;