import React from 'react';
import { Card, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Element } from 'react-scroll'
import 'tachyons';

const Newscard = ({ researchName, researchWebsite, image1, abstract }) => {
    return (
        <div>
            <Card>
                <Card.Header>
                    <h6><b> {researchName} </b></h6>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <Image style={{ width: '100%', height: '30vh' }} src={image1.url} alt="" />
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <p align="justify">
                                {abstract}
                            </p>
                            <Button className="btnr" variant="warning" size="sm" target="_blank" rel="noopener noreferrer" href={researchWebsite}>
                                Read more
                            </Button>{' '}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Newscard;