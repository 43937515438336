import React, { Component } from 'react';
import {Card, Container,Row,Col, ListGroup, CardDeck, Table, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import './research.css';
import './PPP.css'

class PPP extends Component {
    render(){
        return (
      <div>
          <br/>
          <br/>
          <br/>
        <Container fluid>
            <Card.Header>
            <h2><b>Paycheck Protection Program(PPP) Loan Analysis for Georgia</b></h2>
            </Card.Header>
            
            <br/>
            <CardDeck>
                <Card>
                <Card.Header>
                    Top 10 County by number of loans
                </Card.Header>
                <Table responsive size="sm">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>County name</th>
                    <th>Number of loans</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Fulton</td>
                    <td>29,061</td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>Gwinnett</td>
                    <td>14,536</td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Cobb</td>
                    <td>11,538</td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>DeKalb</td>
                    <td>11,528</td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>Chatham</td>
                    <td>4,452</td>
                    </tr>
                    <tr>
                    <td>6</td>
                    <td>Cherokee</td>
                    <td>3,686</td>
                    </tr>
                    <tr>
                    <td>7</td>
                    <td>Henry</td>
                    <td>2,843</td>
                    </tr>
                    <tr>
                    <td>8</td>
                    <td>Forsyth</td>
                    <td>2,739</td>
                    </tr>
                    <tr>
                    <td>9</td>
                    <td>Muscogee</td>
                    <td>2,327</td>
                    </tr>
                    <tr>
                    <td>10</td>
                    <td>Hall</td>
                    <td>2,216</td>
                    </tr>
                </tbody>
                </Table>
                </Card>
                <Card>
                <Card.Header>
                    Top 10 County by number of jobs retained
                </Card.Header>
                <Table responsive size="sm">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>County name</th>
                    <th>Number of jobs</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Fulton</td>
                    <td>330,693</td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>Gwinnett</td>
                    <td>141,658</td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Cobb</td>
                    <td>105,914</td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>DeKalb</td>
                    <td>96,067</td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>Chatham</td>
                    <td>52,510</td>
                    </tr>
                    <tr>
                    <td>6</td>
                    <td>Hall</td>
                    <td>28,290</td>
                    </tr>
                    <tr>
                    <td>7</td>
                    <td>Cherokee</td>
                    <td>28,255</td>
                    </tr>
                    <tr>
                    <td>8</td>
                    <td>Bibb</td>
                    <td>27,312</td>
                    </tr>
                    <tr>
                    <td>9</td>
                    <td>Muscogee</td>
                    <td>25,904</td>
                    </tr>
                    <tr>
                    <td>10</td>
                    <td>Richmond</td>
                    <td>23,357</td>
                    </tr>
                </tbody>
                </Table>
                </Card>
                <Card>
                <Card.Header>
                    Top 10 County by average loan amount
                </Card.Header>
                <Table responsive size="sm">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>County name</th>
                    <th>Average loan amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Atkinson</td>
                    <td>$208,771</td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>Wilkinson</td>
                    <td>$162,096</td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Dade</td>
                    <td>$156,735</td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>Washington</td>
                    <td>$152,990</td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>Stewart</td>
                    <td>$151,923</td>
                    </tr>
                    <tr>
                    <td>6</td>
                    <td>Whitfield</td>
                    <td>$142,194</td>
                    </tr>
                    <tr>
                    <td>7</td>
                    <td>Hall</td>
                    <td>$140,234</td>
                    </tr>
                    <tr>
                    <td>8</td>
                    <td>Fulton</td>
                    <td>$139,664</td>
                    </tr>
                    <tr>
                    <td>9</td>
                    <td>Wilkes</td>
                    <td>$136,957</td>
                    </tr>
                    <tr>
                    <td>10</td>
                    <td>Lanier</td>
                    <td>$130,873</td>
                    </tr>
                </tbody>
                </Table>
                </Card>
                <Card>
                <Card.Header>
                    Top 10 County by average days from PPP opening to approval
                </Card.Header>
                <Table responsive size="sm">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>County name</th>
                    <th>Average Days</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Hancock</td>
                    <td>46</td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>Clayton</td>
                    <td>45</td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Douglas</td>
                    <td>40</td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>Rockdale</td>
                    <td>39</td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>Henry</td>
                    <td>39</td>
                    </tr>
                    <tr>
                    <td>6</td>
                    <td>Chattahoochee</td>
                    <td>38</td>
                    </tr>
                    <tr>
                    <td>7</td>
                    <td>DeKalb</td>
                    <td>38</td>
                    </tr>
                    <tr>
                    <td>8</td>
                    <td>Newton</td>
                    <td>36</td>
                    </tr>
                    <tr>
                    <td>9</td>
                    <td>Paulding</td>
                    <td>34</td>
                    </tr>
                    <tr>
                    <td>10</td>
                    <td>Fulton</td>
                    <td>34</td>
                    </tr>
                </tbody>
                </Table>
                </Card>
            </CardDeck>

            <br/>

            <Card.Header>
                <h5>Distribution of PPP loans across industries and business types</h5>
            </Card.Header>
        
                <div id='divPlotly_06' className="CL_PPP_bar_plot"></div>
                <Helmet>
                    <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                    <script>Plotly.react('divPlotly_06', plotly_data_06.data, plotly_data_06.layout);</script>
                </Helmet>
            <Row>
                <Col xs={12} md = {12} xl = {6}>
                    <Card border="light">
                        <Card.Header>
                            Number of loans given
                        </Card.Header>
                        <div id='divPlotly_01'></div>
                        <Helmet >
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_01', plotly_data_01.data, plotly_data_01.layout);</script>
                        </Helmet>
                    </Card> 
                </Col>
                <Col xs={12} md = {12} lg = {6}>
                    <Card border="light">
                        <Card.Header>
                            Number of jobs retained
                        </Card.Header>
                        <div id='divPlotly_02'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_02', plotly_data_02.data, plotly_data_02.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md = {12} xl = {6}>
                    <Card border="light">
                        <Card.Header>
                            Average loan amount
                        </Card.Header>
                        <div id='divPlotly_03'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_03', plotly_data_03.data, plotly_data_03.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
                <Col xs={12} md = {12} lg = {6}>
                    <Card border="light">
                        <Card.Header>
                            Maximum loan amount
                        </Card.Header>
                        <div id='divPlotly_04'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_04', plotly_data_04.data, plotly_data_04.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md = {12} xl = {6}>
                    <Card border="light">
                        <Card.Header>
                            Average cost of retaining one job
                        </Card.Header>
                        <div id='divPlotly_05'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_05', plotly_data_05.data, plotly_data_05.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
                <Col xs={12} md = {12} xl = {6}>
                <Card border="light">
                        <Card.Header>
                            Average days from PPP opening to approval
                        </Card.Header>
                        <div id='divPlotly_07'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_07', plotly_data_07.data, plotly_data_07.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
            </Row>
            
            <Row>
                <Col xs={12} md = {12} xl = {6}>
                <Card border="light">
                        <Card.Header>
                            Number of loans per COVID case
                        </Card.Header>
                        <div id='divPlotly_08'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_08', plotly_data_08.data, plotly_data_08.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
                <Col xs={12} md = {12} xl = {6}>
                <Card border="light">
                        <Card.Header>
                            Number of jobs retained per COVID case
                        </Card.Header>
                        <div id='divPlotly_09'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_09', plotly_data_09.data, plotly_data_09.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md = {12} xl = {6}>
                <Card border="light">
                        <Card.Header>
                            PPP loans as a percentage of Real GDP of County
                        </Card.Header>
                        <div id='divPlotly_10'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_10', plotly_data_10.data, plotly_data_10.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
                <Col xs={12} md = {12} xl = {6}>
                    <Card border="light">
                        <Card.Header>
                            Percentage of employment retained by PPP loans in County
                        </Card.Header>
                        <div id='divPlotly_11'></div>
                        <Helmet>
                            <script src="https://cdn.plot.ly/plotly-1.42.3.min.js"></script>
                            <script>Plotly.react('divPlotly_11', plotly_data_11.data, plotly_data_11.layout);</script>
                        </Helmet>
                    </Card>
                </Col>
            </Row>
            <Card className="CL_data_outer" bg="light" text="dark" style={{ width: '100%' }}>
				<Card.Header style={{fontWeight: "600"}} as="h5"> Data Sources </Card.Header>
				<Card.Body>
					<Card.Text>
					<ListGroup>
                        <ListGroup.Item>PPP loans (July 6, 2020): <a style={{fontWeight: "600"}} target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/paycheck-protection-program#section-header-11"> U.S. Small Business Administration </a></ListGroup.Item>
                        <ListGroup.Item>Industry classification: <a style={{fontWeight: "600"}} target="_blank" rel="noopener noreferrer" href="https://www.naics.com/search/#naics"> NAICS Association </a> </ListGroup.Item>
                        <ListGroup.Item>COVID-19 (July 7, 2020): <a style={{fontWeight: "600"}} target="_blank" rel="noopener noreferrer" href="https://dph.georgia.gov/covid-19-daily-status-report"> Georgia Department of Public Health </a> </ListGroup.Item>
                        <ListGroup.Item>GDP and Employment (2018): <a style={{fontWeight: "600"}} target="_blank" rel="noopener noreferrer" href="https://www.bea.gov/data/gdp/gdp-county-metro-and-other-areas"> The Bureau of Economic Analysis of the United States Department of Commerce </a> </ListGroup.Item>
                        <ListGroup.Item>Note: As only the range was given for loans above $150K, and loan amount was given for loans below $150K, we used upper limit of range for "Maximum loan amount" amd mid value of range for "Average loan amount", "Average cost of retaining one job" and "PPP loans as a percentage of Real GDP of County".</ListGroup.Item>
					</ListGroup>
					</Card.Text>
				</Card.Body>
			</Card>
            
            <Button size = 'sm' variant="warning"><Link to='/research/covid'>Go Back</Link></Button>{' '}
            <br/>
            <br/>
       

        </Container>
        
     </div>
    );
  }
}

export default PPP;